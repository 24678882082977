import React, { useState, useRef } from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ProductDetailHeroComponent, SideBySide, Block, FloatingImage } from './ProductDetailHero.styles.js';
import { renderLanguage } from 'helpers/languageHelper';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import PromoBox from 'components/shared/PromoBox';
import Button from 'components/shared/Button';

import translate from 'helpers/translate';

import { checkBadges, languageFilter } from 'helpers/badgeHelpers.js';

const ProductDetailHero = (props) => {
  const { fields = {}, params = {}, sitecoreContext = {} } = props;
  const { salsify = {}, globalContent = {}, language = '', viewBag } = sitecoreContext;

  const { productImage = {} } = fields;
  const { homepageLink } = globalContent;

  const { value = {} } = productImage;
  const { src } = value;
  const [showDestini, setShowDestini] = useState(false);
  const modalRef = useRef(null);

  const findInStoreLink = globalContent?.findInStoreLink;
  const productCanImageField = globalContent?.productCanImageField;
  const { site } = sitecoreContext;
  const isFoodserviceSite = site.name === 'bushs-beans-foodservices';
  const {
    alignment = 'left',
    contentPanelBackgroundColor = 'red',
    backgroundColor = null,
    contentPanelTitleColor = null,
    contentPanelCopyColor = null,
    contentPanelCtaColor = null,
  } = params;

  const {
    productName = {},
    consumerUnitFrontStraightOnTransparentPNGFR,
    consumerUnitFrontStraightOnTransparentPNG,
    tradeItemMarketingMessageWeb = {},
    canSizeCollectionList,
    salsifyid,
    isNewItem,
    webBadge,
    countryofOrigin,
  } = salsify;

  const canProductImage =
    productCanImageField === 'consumerUnitFrontStraightOnTransparentPNGFR'
      ? consumerUnitFrontStraightOnTransparentPNGFR
      : consumerUnitFrontStraightOnTransparentPNG;

  const shapeData = {
    title: {
      value: renderLanguage({ language, object: productName }),
    },
    copy: {
      value: `${renderLanguage({ language, object: tradeItemMarketingMessageWeb })}`,
    },
    cta: {
      hasAction: true,
      value: {
        text: findInStoreLink?.title ? findInStoreLink?.title : '',
        href: findInStoreLink?.path ? findInStoreLink?.path : '#',
      },
    },
  };

  const contentBlock = {
    ...shapeData,
    backgroundColor: contentPanelBackgroundColor,
    titleColor: contentPanelTitleColor,
    copyColor: contentPanelCopyColor,
    ctaColor: contentPanelCtaColor,
  };

  const handleCtaClick = (e) => {
    e.preventDefault();

    if (!isServer() && window?.destini) {
      setShowDestini(true);

      // Destini seems to error without the timeout to initialise.
      setTimeout(() => {
        window.destini.init('destinidiv-modal');
        window.destini.loadWidget('default', salsifyid.slice(1, -1));
        disableBodyScroll(modalRef.current);
      }, 250);
    }
  };

  const handleModalClose = () => {
    setShowDestini(false);
    enableBodyScroll(modalRef.current);
  };

  const filteredLanguage = languageFilter(language);
  const activeImgSrc = checkBadges(isNewItem, webBadge, filteredLanguage, countryofOrigin);

  console.log(language.slice(0, 2), 'sliced language');
  console.log(language, 'language');
  console.log(countryofOrigin, 'COO');

  return (
    <ProductDetailHeroComponent>
      {showDestini && (
        <div id="widget-modal" className="modal fade" role="dialog">
          <div className="modal-body" ref={modalRef}>
            <div id="destinidiv-modal" src=""></div>
          </div>
          <Button type="circle" icon="cross" variant="tan" onClick={handleModalClose} />
        </div>
      )}

      <SideBySide
        alignment={alignment}
        variant={contentPanelBackgroundColor}
        backgroundColor={backgroundColor}
      >
        <Block>
          <PromoBox {...contentBlock} variant={contentPanelBackgroundColor} onClick={handleCtaClick}>
            <p className="available-sizes">
              {`${translate(viewBag, 'BushsBeans.Products.ProductDetailHero.Sizes')} ${
                canSizeCollectionList ? canSizeCollectionList : ''
              }`}
            </p>
          </PromoBox>
        </Block>
        {canProductImage?.length && (
          <FloatingImage>
            {canProductImage.map(
              (image, index) =>
                index === 0 && (
                  <img
                    key={`ProductDetailHero-${image.key}-${index}`}
                    src={image.url}
                    alt={image.description}
                  />
                )
            )}
            {activeImgSrc && <img className="badge" src={activeImgSrc} alt={'New Look'} />}
          </FloatingImage>
        )}

        <Block className="background-image" backgroundImage={productImage?.value} />
      </SideBySide>
    </ProductDetailHeroComponent>
  );
};

export default withSitecoreContext()(ProductDetailHero);