import React, { useState } from 'react';
import {DesktopNavComponent, AboutUsSubNav, ProductsSubNav, SubNavTitle, GlobalSubNav} from './DesktopNav.styles.js';
import { FavouritesButton, SearchButton } from '../../MainNav.styles';
import Hamburger from '../FsHamburger';
import NavLink from '../NavLink';
import Link from 'components/shared/Link';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import Icon from 'components/shared/Icon';
import FsMainNavAboutUsLink from '../FsMainNavAboutUsLink';
import FsMainNavProductLink from '../FsMainNavProductLink';
import FsMainNavCardLink from '../FsMainNavCardLink';
import MainNavRibbon from '../MainNavRibbon';
import { underlineText } from 'helpers/stringHelpers';

import Overlay from 'components/shared/Overlay';
import Button from "../../../shared/Button";

export const DesktopNav = ({
  headerContext = {},
  fields,
  sitecoreContext: { globalContent, route, site },
  search,
  setSearch,
  className,
  ...props
}) => {

  const links = globalContent?.navigation;
  const utilityLinks = globalContent?.utilityLinks;
  const logo = globalContent?.logo;
  const navigation = globalContent?.navigation;
  const homePageLink = globalContent?.homepageLink;
  const languageSelector = globalContent?.languageSelectorItems;
  const heartLink = globalContent?.heartLink;
  const heartIcon = globalContent?.heartIcon;
  const ProductsText =
    Array.isArray(links) &&
    links.find(
      ({ type, cards }) =>
        type === 'megamenu-cards' && cards && cards.filter(({ type }) => type === 'product').length > 0
    )?.text;
  const [subNav, setSubNav] = useState(false);
  const [activeLink, setActiveLink] = useState('');

  const supportingLinks = navigation?.reduce((acc, el) => {
    if (el?.supportingLinks?.length) {
      return el.supportingLinks;
    }
    return [...acc];
  }, []);

  const handleLanguageSelector = (newLangCode) => {
    if (!newLangCode) return;

    if (!isServer()) {
      let splitPath = window.location.pathname.split('/');
      splitPath[1] = newLangCode;
      return (window.location.href = splitPath.join('/'));
    }
  };
  return (
    <>
      <DesktopNavComponent search={search} className={`hide-on-print nav-${className}`}>
        <Hamburger aria-label="Menu toggle" className="hamburger"/>

        <div className="logo hide-on-print">
          <Link href={homePageLink}>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src={logo?.src} alt={logo?.alt}/>
          </Link>
        </div>
        <nav aria-label="Main Navigation" role="navigation" className="hide-on-print">
          {Array.isArray(utilityLinks) &&
              utilityLinks.map(({title, path, href}, index) => {
                return (
                    <NavLink
                        className="nav-end-link"
                        role="menuitem"
                        key={`${index}-${title}`}
                        text={title}
                        path={path}
                        href={href}
                    />
                );
              })}

        </nav>
        <div aria-label="Dropdown Navigation" role="menu" id="nav-menu-dropdown" className="nav-end hide-on-print">

          {Array.isArray(links) &&
              links.map(({path, title, type, cards, text}, index) => {
                if (type && type === 'megamenu-cards') {
                  if (cards && cards.length > 0) {
                    return (
                        <NavLink
                            key={`${index}-${title}`}
                            text={title}
                            subNav
                            cardCount={cards.length}
                            className="dropdown-button"
                            path={path}
                            aria-haspopup={type === 'megamenu-cards' ? 'true' : undefined}
                            active={activeLink === title ? 'true' : 'false'}
                            onClick={(event) => {
                              event.preventDefault();
                              setActiveLink(title);
                              setSubNav(text);
                            }}
                        />
                    );
                  }
                }
                return <NavLink className="reg-link" key={`${index}-${title}`} text={title} path={path}/>;
              })
          }

          <Button id="MainNavBtn" target="_blank" text="Contact a Rep" size="small" href="/contact"
                  variant="yellowBlue"/>

        </div>
        <GlobalSubNav active={subNav === 'insights'}>
          <div className="inner">
            <SubNavTitle>
              <div className="subnav-title">Explore Bush's Beans Insights</div>
              <Button text="View All Insights" href="/insights" variant="yellowBrown"/>
            </SubNavTitle>
            <div className={`card-list card-no-${links.find(link => link.text === 'insights')?.cards.length || 0}`}>
              {Array.isArray(links) ?
                  links.map((link, linkIndex) => {
                    return link.text === 'insights' ?
                        link.cards.map(({title, image: {src}, alt, link: {text, path}}, cardIndex) => {
                          return (
                              <FsMainNavCardLink
                                  key={`${linkIndex}-${cardIndex}-${title}`}
                                  text={title}
                                  src={src}
                                  alt={`view ${alt} page`}
                                  path={path}
                                  query={text}
                              />
                          );
                        })
                        : null
                  })
                  : null
              }
            </div>
          </div>
          {/*{supportingLinks?.length && <MainNavRibbon supportingLinks={supportingLinks} className={site?.name === 'bushs-beans-foodservices' ? 'fs-ribbon' : ''} />}*/}
        </GlobalSubNav>
        <GlobalSubNav active={subNav === 'recipes'}>
          <div className="inner">
            <SubNavTitle>
              <div className="subnav-title">Explore Bush's Beans Recipes</div>
              <Button text="View All Recipes" href="/recipes" variant="yellowBrown"/>
            </SubNavTitle>
            <div className={`card-list card-no-${links.find(link => link.text === 'recipes')?.cards.length || 0}`}>
              {Array.isArray(links) ?
                  links.map((link, linkIndex) => {
                    return link.text === 'recipes' ?
                        link.cards.map(({title, image: {src}, alt, link: {text, path}}, cardIndex) => {
                          return (
                              <FsMainNavCardLink
                                  key={`${linkIndex}-${cardIndex}-${title}`}
                                  text={title}
                                  src={src}
                                  alt={`view ${alt} page`}
                                  path={path}
                                  query={text}
                              />
                          );
                        })
                        : null
                  })
                  : null
              }
          </div>
          </div>
          {/*{supportingLinks?.length && <MainNavRibbon supportingLinks={supportingLinks} className={site?.name === 'bushs-beans-foodservices' ? 'fs-ribbon' : ''} />}*/}
        </GlobalSubNav>
        <GlobalSubNav active={subNav === 'products'}>
          <div className="inner">
            <SubNavTitle>
              <div className="subnav-title">Explore Bush's Beans Products</div>
              <Button text="View All Products" href="/products" variant="yellowBrown" />
            </SubNavTitle>
            <div className={`card-list card-no-${links.find(link => link.text === 'products')?.cards.length || 0}`}>
              {Array.isArray(links) ?
                  links.map((link, linkIndex) => {
                    return link.text === 'products' ?
                        link.cards.map(({title, image: {src}, alt, link: {text, path}}, cardIndex) => {
                          return (
                              <FsMainNavCardLink
                                  key={`${linkIndex}-${cardIndex}-${title}`}
                                  text={title}
                                  src={src}
                                  alt={`view ${alt} page`}
                                  path={path}
                                  query={text}
                              />
                          );
                        })
                        : null
                  })
                  : null
              }
            </div>
          </div>
          {/*{supportingLinks?.length && <MainNavRibbon supportingLinks={supportingLinks} className={site?.name === 'bushs-beans-foodservices' ? 'fs-ribbon' : ''} />}*/}
        </GlobalSubNav>
      </DesktopNavComponent>
      {(subNav === 'products' || subNav === 'insights' || subNav === 'recipes') && (
          <Overlay id="overlay" onClick={() => setSubNav('')}/>
      )}
    </>
  );
};

export default withSitecoreContext()(DesktopNav);