import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    CenteredVideoComponentWrapper,
    CenteredVideoOuterWrapper,
} from './CenteredVideo.styles.js';
import {RichText} from 'components/shared/JssOverrides';
import InlineSVG from 'react-inlinesvg';
import Button from 'components/shared/Button';
import YoutubePlayer from './YoutubePlayer.jsx';
import BeanAndBurst from "./InlineSvg/BeanAndBurst.jsx";
import PlayCutOutSvg from "./InlineSvg/PlayCutOutSvg.jsx";
import VideoPlayer from './VideoPlayer.jsx';




const CenteredVideoComponent = (
    {
        title,
        posterText,
        backgroundColor,
        buttonText,
        buttonUrl,
        buttonColor,
        contestVideoPopup,
        videoId,
        poster,
        className,
        field,
        tag,
        editable,
        sitecoreContext,
        id,
        bottomLink1,
        bottomLink2,
        textUnderVideo,
        disclosure,
        backgroundImage,
        modalHeading,
        modalCopy,
        modalLink,
        modalButton,
        iframetitle,
        vimeo,
        ...props
    }
) => {
    const {fields = {}, params = {}} = props;
    const {alignment = 'center'} = params;
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const {globalContent = {}} = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();
    const randomId = Math.random().toString(36).substring(2, 12);
    const [isVideoClicked, setIsVideoClicked] = useState(false);

    const handleVideoClick = () => {
        setIsVideoClicked(true);
    };




    let vidPoster = poster || (videoId ? `http://img.youtube.com/vi/${videoId}/sddefault.jpg` : '');


        const videoEnded = () => {
            setIsVideoClicked(false);
            isVideoClicked(false);
        };

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <CenteredVideoOuterWrapper backgroundColor={backgroundColor} className="centered-video-wrapper">
                <CenteredVideoComponentWrapper>
                    <div className="container">
                        {title && <h2>{title}</h2>}
                        <RichText field={field} tag={tag} className="text-content" editable={editable} />
                        {buttonText && buttonUrl && (
                            <Button text={buttonText} href={buttonUrl} variant={buttonColor} />
                        )}
                        <div className="video-holder">
                            <div className="video-div" style={{ backgroundImage: `url(${poster})` }}>
                                <div className="inner-content">
                                    <InlineSVG
                                        className="svgplaybutton"
                                        cors="anonymous"
                                        width="100"
                                        height="100"
                                        src="https://www.bushbeans.com/-/jssmedia/BushsBeans/rtbbf/Retro-Button-Red.svg"
                                    />
                                    {posterText && <span className="chunk">{posterText}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </CenteredVideoComponentWrapper>
            </CenteredVideoOuterWrapper>
        );
    }

    return (
        <CenteredVideoOuterWrapper backgroundColor={backgroundColor} backgroundImage={backgroundImage} className="centered-video-wrapper" id={id}>
            <CenteredVideoComponentWrapper>
                <BeanAndBurst className="bean-and-burst"/>
                <div className="container">
                    <div className="heading-row">
                        {title && <h2 className="bordonaro-section-heading">{title}</h2>}
                        <RichText className="rich-text" field={field} tag={tag} editable={editable}/>
                        {!textUnderVideo && buttonText && buttonUrl && (
                            <Button text={buttonText} href={buttonUrl} variant={buttonColor}/>
                        )}
                    </div>
                    <div className="video-holder">
                        {vimeo ? (
                            <VideoPlayer
                                title={iframetitle}
                                isClicked={isVideoClicked}
                                videoUrl={vimeo}
                                onVideoEnd={videoEnded}
                            />
                        ) : (
                            <div
                                className="video-div"
                                style={{backgroundImage: `url(${vidPoster})`}}
                                onClick={handleVideoClick}
                                data-videoid={videoId}
                            >
                                {!isVideoClicked ? (
                                    <div className="inner-content">
                                        <button className="play-video-button" aria-label="Play video">
                                            <PlayCutOutSvg className="play-cut-out-button"/>
                                        </button>
                                        {posterText && <span>{posterText}</span>}
                                    </div>
                                ) : (
                                    <YoutubePlayer
                                        title={iframetitle}
                                        isClicked={isVideoClicked}
                                        videoId={videoId}
                                        onVideoEnd={videoEnded}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {textUnderVideo ? (
                    <p className="videoSubText" dangerouslySetInnerHTML={{__html: textUnderVideo}}></p>
                ) : null}

                {textUnderVideo && buttonText && buttonUrl && (
                    <Button id="sweepbtn" target="_blank" text={buttonText} href={buttonUrl} variant={buttonColor}/>
                )}
                {bottomLink1 && bottomLink2 && (
                    <div className="bottom-links">
                        <a href={bottomLink1.split(';')[1]}>{bottomLink1.split(';')[0]}</a>
                        <a href={bottomLink2.split(';')[1]}>{bottomLink2.split(';')[0]}</a>
                    </div>
                )}
                {disclosure ? (
                    <p className="disclosureText" dangerouslySetInnerHTML={{__html: disclosure}}></p>
                ) : null}

            </CenteredVideoComponentWrapper>
        </CenteredVideoOuterWrapper>
    );
};

CenteredVideoComponent.propTypes = {
    sitecoreContext: PropTypes.object,
    fields: PropTypes.shape({
        socialMediaContent: PropTypes.shape({
            value: PropTypes.string,
        }),
    }),
};


export default withSitecoreContext()(CenteredVideoComponent);