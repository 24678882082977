export const searchConfig = (args = {}) => {
  const { type = '' } = args;
  const searchKey = process.env.REACT_APP_SEARCH_API_KEY || 'no key';
  const url = process.env.REACT_APP_EXP_API_DOMAIN || 'no url';

  const paths = {
    product: '/api/experience/search/productgrid',
    recipe: '/api/experience/search/recipegrid',
    article: '/api/experience/search/articlegrid',
    search: '/api/experience/search/site',
    contactForm: '/api/experience/forms/contact-us',
    ids: '/api/experience/search/getPagesById',
  };

  if (type in paths) {
    return {
      searchKey,
      endpoint: `${url}${paths[type]}`,
    };
  }

  return {
    searchKey,
  };
};

export const filterConfig = ({ type, prefilter = [], query = {} }) => {
  const {
    productTypeFilters = [],
    typeOfDishFilters = [],
    beanVarietyFilters = [],
    keyIngredientFilters = [],
    topicFilters = [],
    segmentFilters = [],
    trendFilters = [],
    pageTypeFilters = [],
  } = query;

  const {
    prefilterKeyIngredient = [],
    prefilterProductType = [],
    prefilterTypeOfDish = [],
    prefilterBeanVariety = [],
    prefilterArticleTopic = [],
    prefilterSegments = [],
    prefilterTrends = [],
  } = prefilter;

  const configs = {
    product: {
      productFilters: {
        productTypeFilters: [...prefilterProductType],
      },
    },
    recipe: {
      recipeFilters: {
        typeOfDishFilters: [...prefilterTypeOfDish, ...typeOfDishFilters],
        beanVarietyFilters: [...prefilterBeanVariety, ...beanVarietyFilters],
        productTypeFilters: [...prefilterProductType, ...productTypeFilters],
        keyIngredientFilters: [...prefilterKeyIngredient, ...keyIngredientFilters],
        segmentFilters: [...prefilterSegments, ...segmentFilters],
        trendFilters: [...prefilterTrends, ...trendFilters],
      },
    },
    article: {
      articleFilters: {
        topicFilters: [...prefilterArticleTopic, ...topicFilters],
      },
    },
    search: {
      generalFilters: {
        pageTypeFilters: [],
      },
    },
  };

  if (type in configs) return configs[type];

  return configs.search;
};

export const globalizationConfig = (string = '') => {
  const langRegex = /[a-z]{2}_[A-Z]{2}/;
  const path =
    (langRegex.test(string) && string) || (typeof window !== 'undefined' && window.location.pathname) || '';
  const findMatch = path.match(langRegex) || ['en_US'];

  const [langCode = 'en_US'] = findMatch;
  const [language = 'en', country = 'US'] = langCode.split('_');

  const globalization = {
    language,
    country,
    locale: `${language}-${country}`,
  };

  return globalization;
};
