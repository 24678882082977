import React from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import { ProductGridComponent } from './ProductGrid.styles.js';
import Listing from 'components/shared/Listing';

const ProductGrid = ({ fields = {}, params = {}, sitecoreContext: {site}  }) => {
    const { showFilters = false, pageSize = {} } = fields;
    const isBushsBeansFoodservices = site?.name === 'bushs-beans-foodservices';
    const pageSizeValue =
        (pageSize?.value && !isNaN(parseInt(pageSize.value)) && parseInt(pageSize.value)) || 10;

    const prefilterProductType =
        (fields?.prefilterProductType?.fields?.key?.value && [
            fields?.prefilterProductType?.fields?.key?.value,
        ]) ||
        [];

    const prefilter = { prefilterProductType };

    if (isExperienceEditorActive()) {
        return (
            <div>
                <h1>PRODUCT GRID PLACEHOLDER</h1>
            </div>
        );
    }
    if (isBushsBeansFoodservices){
        return (
            <ProductGridComponent>
                {!isServer() && (
                    <Listing
                        type="product"
                        site={site.name}
                        prefilter={prefilter}
                        showFilters="true"
                        pageSize={pageSizeValue}
                    />
                )}
            </ProductGridComponent>
        );
    } else {
        return (
            <ProductGridComponent>
                {!isServer() && (
                    <Listing
                        type="product"
                        site={site.name}
                        prefilter={prefilter}
                        showFilters={!!showFilters?.value}
                        pageSize={pageSizeValue}
                    />
                )}
            </ProductGridComponent>
        );
    }
};

ProductGrid.propTypes = {
    fields: PropTypes.object,
    params: PropTypes.object,
};

export default withSitecoreContext()(ProductGrid);