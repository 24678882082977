import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/shared/JssOverrides';
import { ContentTextComponent } from './Text.styles.js';
import SocialMediaComponent from './SubTemplates/SocialMediaComponent.jsx';
import NewsletterFormComponent from './SubTemplates/NewsletterFormComponent.jsx';
import CenteredVideoComponent from './SubTemplates/CenteredVideoComponent.jsx';
import CenteredVideo from './SubTemplates/CenteredVideo.jsx';
import VideoRightComponent from './SubTemplates/VideoRightComponent.jsx';
import VideoTimelineComponent from './SubTemplates/VideoTimelineComponent.jsx';
import BeanGameComponent from './SubTemplates/BeanGameComponent.jsx';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const Text = ({ title = '',fields = {}, params = {} }) => {
  const { content = {} } = fields;
  const {
      backgroundColor = 'tan',
      alignment = 'left',
      richTextClass = '',
      ytvideo = '',
      centeredVideoModule = '',
      videoPoster = '',
      socialmedia = '',
      centeredVideo = '',
      signupform = '',
      posterText = '',
      buttonText = '',
      buttonUrl = '',
      buttonColor = '',
      contestVideoPopup = '',
      vid = '',
      videoTimeline = '',
      beanGame = '',
      id = '',
      bottomLink1 = '',
      bottomLink2 = '',
      textUnderVideo = '',
      timeLineVideo1 = '',
      timeLineVideo2 = '',
      timeLineVideo3 = '',
      timeLineVideo4 = '',
      timeLineVideo5 = '',
      timeLineVideo6 = '',
      disclosure = '',
      backgroundImage = '',
      modalHeading = '',
      modalCopy = '',
      modalLink = '',
      modalButton = '',
      includeModal = '',
      iframetitle = '',
      vimeo = '',
  } = params;

    const richTextClassname = richTextClass ? `content-container ${richTextClass}` : 'content-container';
    const experienceEditor = isExperienceEditorActive();
    const vidPoster = videoPoster ? videoPoster : '';

    // console.log('text component title', title);
    // console.log('text component fields', fields);
    if (beanGame === 'true') {
        return (
            <BeanGameComponent
                alignment={alignment}
                backgroundColor={backgroundColor}
                poster = {videoPoster}
                field={content}
                tag="div"
                editable={true}
                id={id}
            />
        );
    }
    if (videoTimeline === 'true') {
        return (
            <VideoTimelineComponent
                alignment={alignment}
                backgroundColor={backgroundColor}
                field={content}
                tag="div"
                editable={true}
                id={id}
                poster={videoPoster}
                title={title}
                timeLineVideo1={timeLineVideo1}
                timeLineVideo2={timeLineVideo2}
                timeLineVideo3={timeLineVideo3}
                timeLineVideo4={timeLineVideo4}
                timeLineVideo5={timeLineVideo5}
                timeLineVideo6={timeLineVideo6}
            />
        );
    }
    if (signupform === 'true') {
        return (
            <NewsletterFormComponent
                alignment={alignment}
                backgroundColor={backgroundColor}
                field={content}
                tag="div"
                editable={true}
            />
        );
    }
    if (socialmedia === 'true') {
        return (
            <SocialMediaComponent
                backgroundColor={backgroundColor}
                alignment={alignment}
                className={richTextClassname}
                field={content}
                tag="div"
                editable={true}
                id={id}
            />
        );
    }
    if(centeredVideoModule){
        return (
            <CenteredVideo
                backgroundColor={backgroundColor}
                alignment={alignment}
                className={richTextClassname}
                video={centeredVideo}
                videoId={vid}
                poster = {videoPoster}
                buttonText={buttonText}
                buttonUrl={buttonUrl}
                buttonColor={buttonColor}
                contestVideoPopup={contestVideoPopup}
                title={fields?.heading?.value || ''}
                field={content}
                tag="div"
                editable={true}
                posterText={posterText}
                id={id}
                bottomLink1={bottomLink1}
                bottomLink2={bottomLink2}
                textUnderVideo={textUnderVideo}
                disclosure={disclosure}
                backgroundImage={backgroundImage}
                modalHeading = {modalHeading}
                modalCopy = {modalCopy}
                modalLink = {modalLink}
                modalButton = {modalButton}
                includeModal = {includeModal}
                iframetitle = {iframetitle}
                vimeo = {vimeo}
            />
        );
    }
    if(centeredVideo){
        return (
            <CenteredVideoComponent
                backgroundColor={backgroundColor}
                alignment={alignment}
                className={richTextClassname}
                video={centeredVideo}
                videoId={vid}
                poster = {videoPoster}
                buttonText={buttonText}
                buttonUrl={buttonUrl}
                buttonColor={buttonColor}
                contestVideoPopup={contestVideoPopup}
                title={title}
                field={content}
                tag="div"
                editable={true}
                posterText={posterText}
                id={id}
                bottomLink1={bottomLink1}
                bottomLink2={bottomLink2}
                textUnderVideo={textUnderVideo}
                disclosure={disclosure}
                backgroundImage={backgroundImage}
                modalHeading = {modalHeading}
                modalCopy = {modalCopy}
                modalLink = {modalLink}
                modalButton = {modalButton}
                includeModal = {includeModal}
                iframetitle = {iframetitle}
                vimeo = {vimeo}
            />
        );
    }
    if (ytvideo) {
        return (
            <VideoRightComponent
                backgroundColor={backgroundColor}
                alignment={alignment}
                rtClass={richTextClassname}
                video={ytvideo}
                poster = {vidPoster}
                content={content}
                tag="div"
                editable={true}
                id={id}
            />
        );
    } else {
        return (
            <ContentTextComponent id={id} backgroundColor={backgroundColor} alignment={alignment}>
                <RichText className={richTextClassname} field={content} tag="div" editable={true}/>
            </ContentTextComponent>
        );
    }
};

Text.propTypes = {
    fields: PropTypes.shape({
        content: PropTypes.object,
    }),
    params: PropTypes.shape({
        alignment: PropTypes.oneOf(['left', 'center', 'right']),
        backgroundColor: PropTypes.oneOf(['tan', 'yellow']),
    }),
};

export default Text;