import styled from 'styled-components';

export const RelatedPagesPromoComponent = styled.section`
  text-align: center;
  padding-top: ${({ theme }) => theme.spacing(80)};
  padding-bottom: ${({ theme }) => theme.spacing(80)};
  background: ${({ theme, background }) => theme.colours[background]};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding-top: ${({ theme }) => theme.spacing(60)};
    padding-bottom: ${({ theme }) => theme.spacing(50)};
  }
  
  
  &.related-pages-bushs-beans-foodservices{
    padding-top: 0;
    padding-bottom: 0;
    margin-top: ${({ theme }) => theme.spacing(80)};
    margin-bottom: ${({ theme }) => theme.spacing(80)};
    overflow: hidden;
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      padding-top: 0;
      padding-bottom: 0;
      margin-top: ${({ theme }) => theme.spacing(160)};
      margin-bottom: ${({ theme }) => theme.spacing(160)};
    }
    .bushs-beans-foodservices-card {
      &.recipe{
        header{
          display: none;
        }
        footer{
          background: ${({ theme }) => theme.colours.blue};
          text-align: left;
          padding: ${({ theme }) => theme.spacing({ top: 32, left: 32, right: 32, bottom: 30 })};
          h3{
            margin-bottom: 0;
            text-align: left;
            min-height: 0;
            ${({ theme }) => theme.breakPoints.minBp('tablet')}{
              min-height: 72px;
            }
          }
          .button-link{
            display: none;
          }
        }
      }
    }
  }

  & h1 {
    color: ${({ theme }) => theme.colours.red};
    margin-bottom: ${({ theme }) => theme.spacing(48)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
  }

  .section-heading {
    margin-bottom: ${({ theme }) => theme.spacing(48)};
    color: ${({ theme }) => theme.colours.red};
    font-size: clamp(2rem, 4vw, 4.5rem);
    font-weight: 600;
    font-family: "apercu condensed pro", impact;
    line-height: clamp(2.125rem, 4vw, 4.75rem);
    text-align: center;
    text-transform: uppercase;
    &.brown{
        color: ${({ theme }) => theme.colours.brown};
    }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
  }
  &.related-pages-bushs-beans-foodservices{
    & .bean-burst {
      color: ${({ theme }) => theme.colours.brown};
    }
  }

  & .bean-burst {
    width: ${({ theme }) => theme.spacing(153)};
    height: ${({ theme }) => theme.spacing(60)};
    color: ${({ theme }) => theme.colours.red};
    margin-bottom: ${({ theme }) => theme.spacing(15)};

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      width: ${({ theme }) => theme.spacing(225)};
      height: ${({ theme }) => theme.spacing(82)};
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }
  .container{
    width: 1580px;
    max-width: 100%;
    margin: auto;
    position: relative;
  }
  .button-container{
    display: flex;
    justify-content: center;
  }
  .slick-slider{
    padding-bottom: 45px;
    @media (max-width: ${({ theme }) => theme.spacing(650)}) {
      width: 80%;
      margin: auto;
      padding-bottom: 30px;
    }
    .slick-list{
      overflow: visible;
      .slick-track{
        display: flex;
        .slick-slide{
          article{
            padding: 0 15px;
          }
        }
      }
    }
  }
`;