import React from 'react';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    ProductCTAContainer,
    ContentWrapper,
    HeadingWrapper,
    CopyWrapper,
    ButtonsWrapper,
    ImageContainerWrapper, ProductCTAWrapper
} from './ProductCTA.styles.js';
import Button from 'components/shared/Button';
import { useDeviceResize } from 'hooks/index.js';
import PropTypes from "prop-types";

const ProductCTA = (props) => {
    const experienceEditor = isExperienceEditorActive();
    const {fields = {}, params = {}, sitecoreContext} = props;
    const {title = '', copy = '', image = null, cta = {}, ctaLocation = {}} = fields;
    const {style = ''} = params;
    const device = useDeviceResize();
    const {site} = sitecoreContext;

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <ProductCTAWrapper site={site}>
                <ProductCTAContainer>
                    <ContentWrapper site={site}>
                        {title && <HeadingWrapper>{typeof title === 'string' ? title : title.value}</HeadingWrapper>}
                        {copy && copy.value && copy.value.length > 5 && (
                            <CopyWrapper>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: typeof copy === 'string' ? copy : copy.value,
                                    }}
                                />
                            </CopyWrapper>
                        )}
                        <ButtonsWrapper site={site}>
                            {cta && cta.value && (
                                <Button
                                    text={cta.value.text}
                                    href={cta.value.href}
                                    variant="blue"
                                    size={device === 'desktop' ? 'large' : 'small'}
                                />
                            )}
                            {ctaLocation && ctaLocation.value && (
                                <Button
                                    text={ctaLocation.value.text}
                                    href={ctaLocation.value.href}
                                    variant="blue"
                                    size={device === 'desktop' ? 'large' : 'small'}
                                />
                            )}
                        </ButtonsWrapper>
                    </ContentWrapper>
                    {image && image.value && image.value.src && (
                        <ImageContainerWrapper>
                            <img
                                src={image.value.src}
                                alt={image.value.alt || 'Product Image'}
                            />
                        </ImageContainerWrapper>
                    )}
                </ProductCTAContainer>
            </ProductCTAWrapper>
        );
    }

    return (
        <ProductCTAWrapper site={site}>
            <ProductCTAContainer>
                <ContentWrapper site={site}>
                    {title && <HeadingWrapper>{typeof title === 'string' ? title : title.value}</HeadingWrapper>}
                    {copy && copy.value && copy.value.length > 5 && (
                        <CopyWrapper>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: typeof copy === 'string' ? copy : copy.value,
                                }}
                            />
                        </CopyWrapper>
                    )}
                    <ButtonsWrapper site={site}>
                        {cta && cta.value && (
                            <Button
                                text={cta.value.text}
                                href={cta.value.href}
                                variant="blue"
                                size={device === 'desktop' ? 'large' : 'small'}
                            />
                        )}
                        {ctaLocation && ctaLocation.value && (
                            <Button
                                text={ctaLocation.value.text}
                                href={ctaLocation.value.href}
                                variant="blue"
                                size={device === 'desktop' ? 'large' : 'small'}
                            />
                        )}
                    </ButtonsWrapper>
                </ContentWrapper>
                {image && image.value && image.value.src && (
                    <ImageContainerWrapper>
                        <img
                            src={image.value.src}
                            alt={image.value.alt || 'Product Image'}
                        />
                    </ImageContainerWrapper>
                )}
            </ProductCTAContainer>
        </ProductCTAWrapper>
    );
};

ProductCTA.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        copy: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        image: PropTypes.object,
        cta: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            onClick: PropTypes.string,
        }),
        ctaLocation: PropTypes.shape({
            href: PropTypes.string,
            text: PropTypes.string,
            onClick: PropTypes.string,
        }),
    }),
    params: PropTypes.shape({
        style: PropTypes.oneOf(['red', 'tan', 'yellow', 'blue','brown', 'transparent']),
    }),
};

export default withSitecoreContext()(ProductCTA);