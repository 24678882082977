import React, { useRef, forwardRef, useState, useEffect } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import {
  FilterWrapper,
  SortWrapper,
  SelectWrapper,
  IconWrapper,
  TypeOptions,
  Options,
  FiltersSelected,
} from './Filters.styles.js';

import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

import translate from 'helpers/translate';
import { useDeviceResize } from 'hooks/index.js';

const FilterHeader = ({
  sitecoreContext = {},
  typeOptions = [],
  sortOptions = [],
  toggleFilters = () => {},
  clearFilters = () => {},
  typeOptionClick = () => {},
  clearChip = () => {},
  applySort = () => {},
  appliedFilters = () => {},
}) => {
  const { viewBag = {} } = sitecoreContext;

  const device = useDeviceResize();
  const sortByRef = useRef(null);
  const [activeButton, setActiveButton] = useState(null);
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleToggleFilters = () => {
    setFiltersOpen(!filtersOpen);
    toggleFilters(!filtersOpen);
    setActiveButton(activeButton ? null : activeButton);
  };

  const handleClose = () => {
    setActiveButton(null);
    setFiltersOpen(false);
    toggleFilters(false);
  };
  return (
    <>
      <div className="top">
        <FilterWrapper onClick={handleToggleFilters}>
          <Icon
              icon={filtersOpen ? "close" : "filter"}
              size="xxl"
              className={filtersOpen ? "active" : ""}
          />
          <span className="title">{translate(viewBag, 'BushsBeans.Search.Labels.Filter', 'Filter')}</span>
        </FilterWrapper>
        {device === 'desktop' && (
          <TypeOptions>
            <Options>
              {typeOptions?.map((option, index) => (
                <li key={`option-${option?.name}-${index}`}>
                  <button className={index === activeButton ? 'active' : ''}
                          onClick={() => {
                            typeOptionClick({ option });
                            setActiveButton(index === activeButton ? null : index);
                            toggleFilters(index !== activeButton);
                          }}>
                    {translate(viewBag, option?.labelKey, option?.label)}
                    <Icon icon="chevron-down" size="sm" />
                  </button>
                </li>
              ))}
            </Options>
          </TypeOptions>
        )}
        <SortWrapper>
          {device === 'desktop' && (
            <span className="title">{translate(viewBag, 'BushsBeans.Search.Labels.SortBy', 'Sort By')}</span>
          )}
          <SelectWrapper>
            <select aria-label="Sort Items" ref={sortByRef} onChange={(event) => applySort(event.target.value)}>
              <option value="0" disabled selected>
                {device === 'desktop'
                  ? translate(viewBag, 'BushsBeans.Search.Labels.MakeSelection', 'Make Selection')
                  : translate(viewBag, 'BushsBeans.Search.Labels.SortBy', 'Sort By')}
              </option>
              {Array.isArray(sortOptions) &&
                sortOptions.map((option, index) => {
                  return (
                    <option key={`sortby-${option?.key}-${index}`} value={option?.key}>
                      {translate(viewBag, option?.labelKey, option?.label)}
                    </option>
                  );
                })}
            </select>
            <IconWrapper>
              <Icon icon="chevron-down" size="md" />
            </IconWrapper>
          </SelectWrapper>
        </SortWrapper>
      </div>
      {appliedFilters?.length > 0 && (
        <div className="bottom">
          <FiltersSelected>
            {appliedFilters?.map((option, index) => (
              <li key={`applied-filter-${option?.name}-${index}`}>
                <Button type="chip" text={option?.name} onClick={() => clearChip({ option })} />
              </li>
            ))}
            <li key={`applied-filter-clear`}>
              <Button
                type="clear"
                text={translate(viewBag, 'BushsBeans.Search.Labels.ClearAllFilters', 'Clear All Filters')}
                onClick={() => clearFilters()}
              />
            </li>
          </FiltersSelected>
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(forwardRef(FilterHeader));