import styled from 'styled-components';

/* the !important rule is used several times to override CSS styles, and is employed only for formatting the printed recipe page */

const getMaxWidth = (result = 6) => {
  const widths = {
    1: '500px',
    2: '700px',
    3: '900px',
    4: '1100px',
    5: '1300px',
    6: '100%',
  };

  if (result in widths) return widths[result];

  return widths[6];
};
export const PromoBoxComponent = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 20, right: 20 })};
  color: ${({ theme }) => theme.colours.yellow};
  text-align: center;
  background-color: ${({ theme }) => theme.colours.red};
  ${({ theme }) => theme.breakPoints.minBp('375px')} {
    padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 35, right: 35 })};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) =>
      `clamp(${theme.spacing(80)}, 10vw, ${theme.spacing(130)}) 
      clamp(${theme.spacing(50)}, 6vw, ${theme.spacing(100)})`};
  }

  h1 {
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.yellow};
    text-transform: uppercase;
    font-weight: normal;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }

  h4 {
    color: ${({ theme }) => theme.colours.white};
    font-family: ${({ theme }) => theme.typography.fontFamily.main};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: 70%;
      margin: auto;
    }
  }

  p {
    color: ${({ theme }) => theme.colours.white};
  }

  > button {
    margin: 0 auto;
  }

  .button-love {
    margin: 0 auto;
    margin-top: ${({ theme }) => theme.spacing(32)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      position: absolute;
      top: ${({ theme }) => theme.spacing(34)};
      right: ${({ theme }) => theme.spacing(34)};
      margin-top: 0;
    }
  }

  .format-promo-box-on-print {
    @media print {
      width: 100vw !important;
      padding-top: 30px !important;
      padding-bottom: 0 !important;
    }
  }
  &.fs-recipe{
    background: ${({ theme }) => theme.colours.blue};
    .button-love{
      display: none;
    }
  }
`;

export const PromoBoxInner = styled.div`
  max-width: 90%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    max-width: 880px;
  }

  .recipe-description-on-print {
    @media print {
      border-top: 2px solid ${({ theme }) => theme.colours.red} !important;
      border-bottom: 2px solid ${({ theme }) => theme.colours.red} !important;
      padding: 5px 0px 5px 0px;
      font-size: 12px;
      color: black !important;
      line-height: 15px;
    }
  }
`;

export const RecipeImageWrapper = styled.div`
  display: none;
  @media print {
    display: block;
    margin-top: ${({ theme }) => theme.spacing(22)};
    img {
      max-width: 150px;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: none;
  @media print {
    display: block;
    padding-right: 30px;
    img {
      max-width: 70px;
    }
  }
`;

export const PromoBoxOuter = styled.div`
  display: flex;
  @media print {
    justify-content: space-evenly;
    align-items: center;
  }
  .recipe-grid-on-print {
    @media print {
      max-width: fit-content !important;
    }
  }
  .recipe-grid-on-print > li:last-of-type {
    @media print {
      border-bottom: 2px solid ${({ theme }) => theme.colours.red} !important;
    }
  }
`;

export const LogoAndLongNameWrapper = styled.div`
  @media print {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
  .recipe-title-on-print {
    @media print {
      font-family: ${({ theme }) => theme.typography.fontFamily.heading} !important;
      font-size: 24px !important;
      font-weight: 600 !important;
      color: ${({ theme }) => theme.colours.red} !important;
    }
  }
`;

export const RecipeGrid = styled.ul`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-template-columns: ${(props) => (props.singleRow ? 'auto' : 'repeat(3, 1fr)')};
  margin-top: ${({ theme }) => theme.spacing(40)};
  margin-left: auto;
  margin-right: auto;
  @media print {
    margin-top: ${({ theme }) => theme.spacing(0)};
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-template-rows: auto;
    max-width: ${({ results }) => getMaxWidth(results)};
    margin: ${({ theme }) => theme.spacing(50)} auto 0;
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${({ theme }) => theme.spacing(10)};
    border: ${({ theme }) => `1px solid ${theme.colours.yellow}`};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => `${theme.spacing(20)} ${theme.spacing(30)}`};
    }

    p {
      text-transform: uppercase;
    }

    img {
      margin-top: ${({ theme }) => theme.spacing(5)};
    }

    span {
      display: block;
      color: ${({ theme }) => theme.colours.white};
      font-weight: normal;
      font-size: ${({ theme }) => theme.spacing(9)};
      text-transform: uppercase;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        font-size: ${({ theme }) => theme.spacing(13)};
      }
    }
  }

  .recipe-grid-items-on-print {
    @media print {
      border: 2px solid ${({ theme }) => theme.colours.red} !important;
      border-bottom: 0 !important;
      display: inline !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      text-align: left !important;
    }
  }

  .uniform-text-on-print {
    @media print {
      font-family: ${({ theme }) => theme.typography.fontFamily.heading} !important;
      font-size: 12px !important;
      font-weight: 600 !important;
      color: black !important;
    }
  }

  .colon-on-print::after {
    @media print {
      content: ':‏‏‎‏‏‎';
      padding-right: 3px;
    }
  }
`;