import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyles = createGlobalStyle`
  ${reset}

  /* Importing the self hosted fonts */
  ${({ theme }) => theme.typography.fonts}

  html, body {
    color: ${({ theme }) => theme.colours.brown};
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    font-family: ${({ theme }) => theme.typography.fontFamily.main};
  }
  #bushs-beans-foodservices {
	  background-color: ${({ theme }) => theme.colours.tan};
  }
  ul {
    list-style: none;
  }
  .no-li{
    list-style: none;
    margin-left: 0 !important;
    &.h4{
      text-align: left;
    }
  }

  .h1 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(32)}, 4vw, ${theme.spacing(72)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(34)}, 4vw, ${theme.spacing(76)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(30)};
    sup {
      font-size: 70%;
      top: -15px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(32)}, 4vw, ${theme.spacing(72)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(34)}, 4vw, ${theme.spacing(76)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(30)};

    sup {
      font-size: 70%;
      top: -15px;
    }
  }

  h2 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(28)};
  }

  .h2 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(28)};
  }

  h3 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(32)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(18)}, 4vw, ${theme.spacing(36)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(24)};
  }

  .h3 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(32)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(18)}, 4vw, ${theme.spacing(36)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(24)};
  }

  h4 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(18)}, 4vw, ${theme.spacing(32)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(20)};
  }

  .h4 {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(18)}, 4vw, ${theme.spacing(32)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(20)};
  }

  h5 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.spacing(16)};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => theme.spacing(18)};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(18)};
  }

  .h5 {
    font-weight: 600;
    font-size: ${({ theme }) => theme.spacing(16)};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => theme.spacing(18)};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(18)};
  }

  .hero {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(40)}, 8vw, ${theme.spacing(140)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: ${({ theme }) => `clamp(${theme.spacing(44)}, 8vw, ${theme.spacing(144)})`};
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(64)};

    u {
      display: inline-block;
      font-size: 85%;
      vertical-align: top;
    }
  }

  p {
    font-weight: 400;
    font-size: ${({ theme }) => theme.spacing(16)};
    line-height: ${({ theme }) => theme.spacing(24)};
    margin-bottom: ${({ theme }) => theme.spacing(48)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      font-size: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(24)})`};
      line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(32)})`};
    }

    &.content {
      line-height: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    }

    &.small {
      font-weight: 400;
      font-size: ${({ theme }) => theme.spacing(14)};
      line-height: ${({ theme }) => theme.spacing(16)};
  
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        font-size: ${({ theme }) => `clamp(${theme.spacing(14)}, 4vw, ${theme.spacing(16)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(16)}, 4vw, ${theme.spacing(18)})`};
      }
    }
  }
  
  .sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  sup {
    position: relative;
    top: -5px;
    left: 1px;
    font-size: 80%;
    line-height: 0.5em;
    vertical-align: baseline;
  }

  .emphasis {
    color: ${({ theme }) => theme.colours.blue};
    font-weight: 400;
    font-size: ${({ theme }) => `clamp(${theme.spacing(40)}, 6vw, ${theme.spacing(96)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.emphasis};
    line-height: 83%;
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing(60)};


    &.small {
      font-size: ${({ theme }) => `clamp(${theme.spacing(32)}, 4vw, ${theme.spacing(40)})`};
      line-height: 90%;
      margin-bottom: ${({ theme }) => theme.spacing(16)};
    }

    &.large {
      font-size: ${({ theme }) => `clamp(${theme.spacing(96)}, 8vw, ${theme.spacing(140)})`};
      line-height: 85%;
    }

    &.x-large {
      font-size: ${({ theme }) => `clamp(${theme.spacing(140)}, 10vw, ${theme.spacing(200)})`};
      line-height: 85%;
      margin-bottom: ${({ theme }) => theme.spacing(80)};
    }
  }

  .vanity {
    font-weight: 600;
    font-size: ${({ theme }) => `clamp(${theme.spacing(90)}, 10vw, ${theme.spacing(200)})`};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    line-height: 90%;

    @supports (-webkit-text-stroke: 1px) {
        -webkit-text-stroke: 2px ${({ theme }) => theme.colours.copper};
        -webkit-text-fill-color: transparent;
    }

    &.large {
      font-size: ${({ theme }) => `clamp(${theme.spacing(100)}, 12vw, ${theme.spacing(240)})`};
    }
  }

  .color {
    &-white {
      color: ${({ theme }) => theme.colours.white};
    }
    &-red {
      color: ${({ theme }) => theme.colours.red};
    }
    &-yellow {
      color: ${({ theme }) => theme.colours.yellow};
    }
    &-blue {
      color: ${({ theme }) => theme.colours.blue};
    }
    &-tan {
      color: ${({ theme }) => theme.colours.tan};
    }
    &-brown {
      color: ${({ theme }) => theme.colours.brown};
    }
    &-adaBrown {
      color: ${({ theme }) => theme.colours.adaBrown};
    }
  }



  a {
    color:  ${({ theme }) => theme.colours.red};
    text-decoration: underline;
    cursor: pointer;
      
    &:hover {
      color:  ${({ theme }) => theme.colours.blue};
    }

    &.button-link {
      text-decoration: none;
    }

    &.card-link {
      color: inherit;
      text-decoration: none;
    }

    &.link-color {
      text-decoration: underline;
  
      &-yellow {
        color:  ${({ theme }) => theme.colours.yellow};
        
        &:hover {
          color:  ${({ theme }) => theme.colours.tan};
        }
      }

      &-tan {
        color:  ${({ theme }) => theme.colours.tan};
        
        &:hover {
          color:  ${({ theme }) => theme.colours.yellow};
        }
      }
  
      &-red {
        color:  ${({ theme }) => theme.colours.red};
        
        &:hover {
          color:  ${({ theme }) => theme.colours.blue};
        }
      } 
      
      &-blue {
        color:  ${({ theme }) => theme.colours.blue};
        
        &:hover {
          color:  ${({ theme }) => theme.colours.yellow};
        }
      }
    }
  }
  strong{
    font-weight: 900;
  }
  em{
    font-style: italic;
  }
  i{
    font-style: italic;
  }
  b{
    font-weight: 900;
  }
  /* Button style reset */
  button {
    background: none;
    border: none;
  }


  .hide-on-print {
    @media print {
      display: none !important;
      
      @page { 
        margin: 0; 
      }
      
      body { 
        margin: 1.6cm; 
      }
    }
  }

  /* Remove padding and margin when printing to reduce space between components */
  .shrink-on-print {
    @media print { 
      margin: 0 auto;
      padding: 0;
    }
  }

  /* Flex a container on print */
  /* The !important rule is used below as other CSS were overwriting styling for elements on a print display. */
  .flex-on-print {
    @media print { 
      display: inline-flex !important;
    }
  }

  /** storybook **/
  h1, h2, h3, h4, h5, p {
    &.sbdocs {
      text-align: left;
      }
  } 
  .buttonDiv{
    .button-default{
      font-size: 24px;
      font-family: "apercu pro", helvetica, verdana, sans-serif;
      font-weight: 900;
      span{
        &:nth-child(1){
          font-size: 20px;
          font-family: "apercu pro", helvetica, verdana, sans-serif;
          font-weight: 900;
          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            font-size: 24px;
          }
        }
      }
    }
  }
  .button-link{
    .button-default{
      font-size: 24px;
      font-weight: 900;
      span{
        &:nth-child(1){
          font-size: 20px;
          font-family: "apercu pro", helvetica, verdana, sans-serif;
          font-weight: 900;
          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            font-size: 24px;
          }
        }
      }
    }
  }
  .grid.recipe {
    .button-link{
      div{
        span{
          font-size: 24px;
        }
      }
    }
  }
  .button-loadmore{
    span{
      color: #fff;
      transition: all 0.25s ease-in-out 0s;
      &:hover{
        color: #d43631;
      }
    }
  }
  #onetrust-consent-sdk #onetrust-banner-sdk #onetrust-policy-text a{
    transition: all 0.3s ease-in-out;
    color: #930B06 !important;
    &:hover{
      color: #d43631 !important;
    }
  }
  #onetrust-accept-btn-handler{
    color: #fff;
    background: #AE1913 !important;
    border: 1px solid #AE1913 !important;
    transition: all 0.3s ease-in-out;
    &:hover{
      background: #d43631 !important;
      border: 1px solid #d43631 !important;
    }
  }
  .force-white{
    color: #fff !important;
  }
  .c-card-h{
    color:  ${({ theme }) => theme.colours.red};
    font-weight: 400;
    text-transform: uppercase;
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }
  }
  .content-container{
    p{
      a{
        font-weight: 900;
      }
    }
  }
  .content-headline{
    font-weight: 700;
    font-size: 69px;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;
    color: #D43631;
    display: block;
    width: 100%;
    @media (max-width: 900px){
      font-size: 32px;
    }
  }
  .content-p-sp{
    width: 668px;
    max-width: 100%;
    margin: auto;
    line-height: 1.4;
    display: block;
    text-align: center;
    font-size: 20px;
    @media (max-width: 900px){
      font-size: 16px;
    }
  }
  .trails-text{
    white-space: nowrap;
  }
  .heading-with-sm-top{
    .small-pre-heading{
      display: block;
      text-align: center;
      font-size: 40px;
      color: ${({ theme }) => theme.colours.yellow};
      margin-bottom: 20px;
      @media (max-width: 900px){
        font-size: 20px;
        margin-bottom: 10px
      }
    }
    h2{
      font-size: 69px;
      line-height: 1.1;
      text-align: center;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colours.yellow};
      display: block;
      width: 100%;
      line-height: 1;
      @media (max-width: 900px){
        font-size: 32px;
      }
    }
  }
  #timer{
    display: flex;
    justify-content: space-evenly;
    width: 370px;
    align-self: center;
    margin: 40px auto 0 auto;
    .sep{
      font-size: 48px;
      line-height: 60px;
      margin: 0 10px;
      text-align: center;
      color: ${({ theme }) => theme.colours.red};
      @media (max-width: 900px){
        font-size: 24px;
        line-height: 35px;
      }
    }
    .clockElement{
      color: ${({ theme }) => theme.colours.red};
      font-size: 56px;
      font-weight: 600;
      line-height: 72px;
      text-align: center;
      display: flex;
      flex-direction: column;
      max-width: 100px;
      width: 80px;
      justify-content: center;
      line-height: 70px;
      span{
        font-size: 20px;
        font-weight: 600;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: center;
      }
      @media (max-width: 900px){
        font-size: 40px;
        line-height: 40px;
        span{
          font-size: 16px;
          font-weight: 600;
          line-height: 34px;
        }
      }
    }
  }
  a{
    &.yellow-red-solid{
      text-decoration: none !important;
      button{
        text-decoration: none !important;
        background-color: ${({ theme }) => theme.colours.yellow} !important;
        color: ${({ theme }) => theme.colours.red} !important;
        border-color: ${({ theme }) => theme.colours.yellow} !important;
        & span:nth-child(2) {
          background-color: ${({ theme }) => theme.colours.red} !important;
        }
        & span:nth-child(3) {
          & svg {
            color: ${({ theme }) => theme.colours.red} !important;
          }
        }
        &:hover {
          background-color: ${({ theme }) => theme.colours.red} !important;
          color: ${({ theme }) => theme.colours.yellow} !important;
          border-color: ${({ theme }) => theme.colours.yellow} !important;
          & span:nth-child(2){
            background-color: ${({ theme }) => theme.colours.yellow};
          }
          & span:nth-child(3) {
            & svg {
              color: ${({ theme }) => theme.colours.yellow} !important;
            }
          }
        }
      }
    }
    &:hover {
      &.yellow-red-solid{
          background-color: ${({ theme }) => theme.colours.red} !important;
          color: ${({ theme }) => theme.colours.yellow} !important;
          border-color: ${({ theme }) => theme.colours.yellow} !important;
          & span:nth-child(2){
            background-color: ${({ theme }) => theme.colours.yellow};
          }
          & span:nth-child(3) {
            & svg {
              color: ${({ theme }) => theme.colours.yellow} !important;
            }
          }
      }
    }
  }
  a{
    &.yellow-clear-hover{
      text-decoration: none !important;
      button{
        background-color: transparent;
        text-decoration: none !important;
        & span:nth-child(2) {
          background-color: ${({ theme }) => theme.colours.yellow};
        }
        & span:nth-child(3) {
          & svg {
            color: ${({ theme }) => theme.colours.yellow} !important;
          }
        }
        &:hover {
          background-color: ${({ theme }) => theme.colours.yellow} !important;
          color: ${({ theme }) => theme.colours.blue} !important;
          border-color: ${({ theme }) => theme.colours.yellow} !important;
          & span:nth-child(2){
            background-color: ${({ theme }) => theme.colours.blue} !important;
          }
          & span:nth-child(3) {
            & svg {
              color: ${({ theme }) => theme.colours.blue} !important;
            }
          }
        }
      }
    }
    &:hover{
      &.yellow-clear-hover{
        button{
          background-color: ${({ theme }) => theme.colours.yellow} !important;
          color: ${({ theme }) => theme.colours.blue} !important;
          border-color: ${({ theme }) => theme.colours.yellow} !important;
          & span:nth-child(2){
            background-color: ${({ theme }) => theme.colours.blue} !important;
          }
          & span:nth-child(3) {
            & svg {
              color: ${({ theme }) => theme.colours.blue} !important;
            }
          }
        }
      }
    }
  }
  .khakMv{
    .promoboxWrap{
      width: 100% !important;
    }
  }
  //.sc-irOQnY, .jxiItv{
  //  padding-top: 100px;
  //  @media (max-width: 900px){
  //    padding-top: 50px;
  //  }
  //  @media (max-width: 600px){
  //    padding-top: 20px;
  //  }
  //}
  .khakMv, .bDCxzw{
    .promo-box{
      a{
      button{
        background-color: transparent;
        text-decoration: none !important;
        & span:nth-child(2) {
          background-color: ${({ theme }) => theme.colours.yellow};
        }
        & span:nth-child(3) {
          & svg {
            color: ${({ theme }) => theme.colours.yellow} !important;
          }
        }
        &:hover {
          background-color: ${({ theme }) => theme.colours.yellow} !important;
          color: ${({ theme }) => theme.colours.blue} !important;
          border-color: ${({ theme }) => theme.colours.yellow} !important;
          & span:nth-child(2){
            background-color: ${({ theme }) => theme.colours.blue} !important;
          }
          & span:nth-child(3) {
            & svg {
              color: ${({ theme }) => theme.colours.blue} !important;
            }
          }
        }
      }
      }
    }
  }
  .hero-bg-lg{
    .button-link{
      text-decoration: none !important;
      button{
        text-decoration: none !important;
        background-color: ${({ theme }) => theme.colours.yellow} !important;
        color: ${({ theme }) => theme.colours.red} !important;
        border-color: ${({ theme }) => theme.colours.yellow} !important;
        & span:nth-child(2) {
          background-color: ${({ theme }) => theme.colours.red} !important;
        }
        & span:nth-child(3) {
          & svg {
            color: ${({ theme }) => theme.colours.red} !important;
          }
        }
        &:hover {
          background-color: ${({ theme }) => theme.colours.red} !important;
          color: ${({ theme }) => theme.colours.yellow} !important;
          border-color: ${({ theme }) => theme.colours.yellow} !important;
          & span:nth-child(2){
            background-color: ${({ theme }) => theme.colours.yellow} !important;
          }
          & span:nth-child(3) {
            & svg {
              color: ${({ theme }) => theme.colours.yellow} !important;
            }
          }
        }
      }
    }
  }
  .content{
    p{
      a{
        text-decoration: underline;
        font-weight: 900;
      }
    }
  }
  .form-footer{
    em{
      a{
        color: #b00500;
        font-weight: 900;
      }
    }
    p{
      a{
        color: #b00500;
        font-weight: 900;
      }
    }
  }
  .tagline{
      background: ${({ theme }) => theme.colours.yellow} !important;
	  .tagline-h1{
		  display: block;
		  color: ${({ theme }) => theme.colours.red} !important;
          text-wrap: nowrap;
		  font-family: 'apercu pro' !important;
          font-weight: 500 !important;
          letter-spacing: -1px;
		  span{
			  text-decoration: underline;
		  }
	  }
  }
  #four-oh-four{
      .hidden{
          display: none;
      }
      .search-on-p{
          max-width: 1200px;
          margin: auto;
		  .input-container input{
              font-size: 1.3rem;
          }
      }
      &.f-s{
          .inner{
			  padding-bottom: 100px;
			  h2{
				  font-size: 72px;
				  font-style: normal;
				  font-weight: 700;
				  font-family: 'apercu condensed pro', 'impact';
				  @media (max-width: 1250px){
					  font-size: 38px;
				  }
			  }
          }
      }
      .inner{
		  padding-top: 50px;
		  h2{
			  color: ${({ theme }) => theme.colours.red};
			  margin: 30px auto 10px auto;
			  line-height: 1.2;
			  font-size: 55px;
			  font-family: apercu pro, 'apercu pro', impact;
			  @media (max-width: 1250px){
				  font-size: 38px;
			  }
		  }
		  h3{
			  color: ${({ theme }) => theme.colours.red};
			  font-size: 27px;
			  font-family: apercu pro, 'apercu pro', impact;
			  @media (max-width: 1250px){
				  font-size: 20px;
			  }
		  }
		  h2, h3, p{
			  &.blue{
				  color: ${({ theme }) => theme.colours.blue};
			  }
			  &.red{
				  color: ${({ theme }) => theme.colours.red};
			  }
			  &.yellow{
				  color: ${({ theme }) => theme.colours.yellow};
			  }
			  &.brown{
				  color: ${({ theme }) => theme.colours.brown};
			  }
		  }   
      }
	  .buttonUl {
		  width: 1400px;
		  max-width: 98%;
		  display: flex;
		  justify-content: center;
		  margin: 30px auto;
		  flex-wrap: wrap;

		  li {
			  flex-basis: 0;
			  flex-grow: 1;
			  width: 50%;
              min-width: 280px;
			  a {
				  background-color: ${({ theme }) => theme.colours.yellow};
				  color: ${({ theme }) => theme.colours.brown};
				  padding: 17px 0px 15px 0px;
				  margin: 20px;
				  font-size: 20px;
				  line-height: 1;
				  border-radius: 25px;
				  font-weight: 700;
				  font-family: apercu condensed pro, 'apercu pro', impact;
				  transition: all 0.4s ease-in-out;
				  display: block;
				  text-align: center;
                  text-transform: uppercase;
				  &:hover {
					  background-color: ${({ theme }) => theme.colours.brown};
					  color: ${({ theme }) => theme.colours.yellow};
				  }
			  }
		  }
          &.foodservice{
			  li{
				  flex-grow: 0;
                  a{
					  text-align: center;
					  align-content: center;
					  justify-content: center;
					  display: flex;
                      background: transparent;
                      button{
                          white-space: nowrap;
                      }
                  }
              }
          }
	  }
  }
  .ot-floating-button__front{
	  background-image: url(https://www.bushbeans.com/-/media/yellow-b-burst.png)!important;
  }
  #ot-sdk-btn-floating .ot-floating-button__front.custom-persistent-icon{
      background-image: url('/-/media/yellow-b-burst.png') !important;
      background-size: contain !important;
      background-color: #1e70b8 !important;
	  border: 2px solid #1e70b8 !important;
  }
  #ot-sdk-btn-floating.ot-floating-button{
      width: 65px !important;
      height: 65px !important;
  }
  .text-link{
	  font-style: normal;
	  font-weight: 700;
	  font-size: 20px;
	  line-height: 32px;
	  text-decoration-line: underline;
        &-white {
          color: ${({ theme }) => theme.colours.white};
        }
        &-red {
          color: ${({ theme }) => theme.colours.red};
        }
        &-yellow {
          color: ${({ theme }) => theme.colours.yellow};
        }
        &-blue {
          color: ${({ theme }) => theme.colours.blue};
        }
        &-tan {
          color: ${({ theme }) => theme.colours.tan};
        }
        &-brown {
          color: ${({ theme }) => theme.colours.brown};
        }
        &-adaBrown {
          color: ${({ theme }) => theme.colours.adaBrown};
        }
  }
  .text{
       &-white {
          color: ${({ theme }) => theme.colours.white}!important;
        }
        &-red {
          color: ${({ theme }) => theme.colours.red} !important;
        }
        &-yellow {
          color: ${({ theme }) => theme.colours.yellow} !important;
        }
        &-blue {
          color: ${({ theme }) => theme.colours.blue} !important;
        }
        &-tan {
          color: ${({ theme }) => theme.colours.tan} !important;
        }
        &-brown {
          color: ${({ theme }) => theme.colours.brown} !important;
        }
        &-adaBrown {
          color: ${({ theme }) => theme.colours.adaBrown} !important;
        }
  }
  .link-in-pb{
	  align-content: baseline;
	  display: flex;
	  justify-content: center;
	  margin: 20px auto;
  }
  .sc-jONnTn.dAjBPr{
      max-width: 100% !important;
      width: 100% !important;
  }
  .buttonYellowRed{
	  align-content: center;
	  height: calc(-4px + 3.125rem);
	  color: ${({ theme }) => theme.colours.yellow};
	  font-size: 1rem;
	  font-family: "apercu pro", helvetica, verdana, sans-serif;
	  background-color: ${({ theme }) => theme.colours.red};
	  outline: 0px;
	  cursor: pointer;
	  transition: color 0.25s ease-in-out 0s, background-color 0.25s ease-in-out 0s, border-color 0.25s ease-in-out 0s;
	  user-select: none;
	  text-decoration: none;
	  display: flex;
	  align-items: center;
	  border: 3px solid ${({ theme }) => theme.colours.yellow};
	  border-radius: 100px;
	  height: 60px;
	  span:nth-child(1){
		  font-size: 24px;
		  display: flex;
		  -webkit-box-align: center;
		  align-items: center;
		  font-weight: bold;
		  padding: 1rem;
      }
	  span:nth-child(2) {
		  display: flex;
		  width: 3px;
		  height: calc(100% + 6px);
		  margin-top: -0.0625rem;
		  background-color: ${({ theme }) => theme.colours.yellow};
		  transition: background-color 0.25s ease-in-out 0s;
	  }
	  span:nth-child(3) {
		  display: flex;
		  max-width: 48px;
		  svg{
			  margin: 0 10px;
			  display: inline-block;
			  fill: ${({ theme }) => theme.colours.yellow};
			  path{
				  stroke: ${({ theme }) => theme.colours.yellow};
			  }
		  }
	  }
      &:hover{
            background-color: ${({ theme }) => theme.colours.yellow};
            color: ${({ theme }) => theme.colours.red};
		  span:nth-child(3) {
			  svg{
				  path{
					  stroke: ${({ theme }) => theme.colours.red};
				  }
			  }
		  }
      }
  }
  .buttonYellowBlue{
	  align-content: center;
	  height: calc(-4px + 3.125rem);
	  color: ${({ theme }) => theme.colours.yellow};
	  font-size: 1rem;
	  font-family: "apercu pro", helvetica, verdana, sans-serif;
	  background-color: ${({ theme }) => theme.colours.blue};
	  outline: 0px;
	  cursor: pointer;
	  transition: color 0.25s ease-in-out 0s, background-color 0.25s ease-in-out 0s, border-color 0.25s ease-in-out 0s;
	  user-select: none;
	  text-decoration: none;
	  display: flex;
	  align-items: center;
	  border: 3px solid ${({ theme }) => theme.colours.yellow};
	  border-radius: 100px;
	  height: 60px;
	  span:nth-child(1){
		  display: flex;
		  -webkit-box-align: center;
		  align-items: center;
		  font-weight: bold;
		  padding: 1rem;
	  }
	  span:nth-child(2) {
		  display: flex;
		  width: 3px;
		  height: calc(100% + 6px);
		  margin-top: -0.0625rem;
		  background-color: ${({ theme }) => theme.colours.yellow};
		  transition: background-color 0.25s ease-in-out 0s;
	  }
	  span:nth-child(3) {
		  display: flex;
		  max-width: 48px;
		  svg{
              margin: 0 10px;
              display: inline-block;
			  fill: ${({ theme }) => theme.colours.yellow};
              path{
                  stroke: ${({ theme }) => theme.colours.yellow};
              }
		  }
	  }
	  img{
          transition: all 0.25s ease-in-out;
      }
      &:hover{
		  background-color: ${({ theme }) => theme.colours.yellow};
		  color: ${({ theme }) => theme.colours.blue};
		  span:nth-child(3) {
			  svg{
				  path{
					  stroke: ${({ theme }) => theme.colours.blue};
				  }
			  }
		  }
          img{
			  filter: invert(.64);
          }
      }
  }
  .sc-jYCGvq.hyNuoW{
      padding: 0;
  }

  @keyframes pulseone {
	  0% {
		  transform: rotateZ(0deg) scale(1) skew(0deg, 0deg);
	  }
	  50% {
		  transform: scale(1.02) rotateZ(1deg) skew(10deg, 0deg);
	  }
	  100% {
		  transform: scale(1.02) rotateZ(0deg) skew(0deg, 0deg);
	  }
  }
  @keyframes pulsetwo {
	  0% {
		  transform: rotateZ(0deg) scale(1) skew(0deg, 0deg);
	  }
	  50% {
		  transform: scale(1.02) rotateZ(1deg) skew(10deg, 0deg);
	  }
	  100% {
		  transform: scale(1.02) rotateZ(0deg) skew(0deg, 0deg);
	  }
  }
  @keyframes pulsethree {
	  0% {
		  transform: rotateZ(0deg) scale(1) skew(0deg, 0deg);
	  }
	  50% {
		  transform: scale(1.02) rotateZ(1deg) skew(-10deg, 0deg);
	  }
	  100% {
		  transform: scale(1.02) rotateZ(0deg) skew(0deg, 0deg);
	  }
  }
  @keyframes pulsefour {
	  0% {
		  transform: rotateZ(0deg) scale(1) skew(0deg, 0deg);
	  }
	  50% {
		  transform: scale(1.02) rotateZ(4deg) skew(12deg, 0deg);
	  }
	  100% {
		  transform: scale(1.02) rotateZ(0deg) skew(0deg, 0deg);
	  }
  }
  #bsnw3 {
	  animation: pulsethree 4s linear infinite alternate;
	  animation-delay: 2s;
	  transform-origin: center center;
	  position: relative;
	  display: inline-block;
  }

  #bsnw1 {
	  animation: pulseone 4s linear infinite alternate;
	  animation-delay: 2s;
	  position: relative;
	  display: inline-block;
	  transform-origin: left center;
  }

  #bsnw2 {
	  animation: pulsetwo 4s linear infinite alternate;
	  animation-delay: 2s;
	  position: relative;
	  display: inline-block;
	  transform-origin: center center;
  }

  #bsnw4 {
	  animation: pulsefour 3s linear infinite alternate;
	  animation-delay: 2s;
	  position: relative;
	  display: inline-block;
	  transform-origin: right center;
  }
  .fade-in {
	  opacity: 0;
	  transition: opacity 0.5s ease-in-out;
  }

  .fade-in.visible {
	  opacity: 1;
  }
  .w-1400-max-90{
	  width: 1400px;
	  margin: auto;
	  max-width: 90% !important;
  }
  @keyframes bounce {
	  0% {
		  transform: translate(0, 0);
	  }
	  33% {
		  transform: translate(-10px, 0);
	  }
	  66% {
		  transform: translate(-7px, 0);
	  }
	  100% {
		  transform: translate(-10px, 0);
	  }
  }
  @keyframes bob {
	  0% {
		  transform: translate(0, 0);
	  }
	  50% {
		  transform: translate(0, -10px);
	  }
	  100% {
		  transform: translate(0, 0);
	  }
  }
  .chunk{
	  font-family: 'chunk' !important;
  }
  #rollthatscrolltext{
      padding: 0;
  }
  #thirtyscrolltext{
      padding: 0;
  }
  .promo-box{
	  .button-link{
		  margin-top: 20px;
	  }
  }
  .slide{
      .promo{
          .p{
              margin-bottom: 20px;
          }
      }
  }
  nav{
      #search-button{
		  align-self: center;
      }
  }
  .bushs-beans-foodservices{
      .nav-bushs-beans-foodservices, .mobile-nav-bushs-beans-foodservices{
		  background-color: ${({ theme }) => theme.colours.blue};
      }
      #subscribesubmit{
          svg{
              color: ${({ theme }) => theme.colours.blue};
          }
          &:hover{
              background: ${({ theme }) => theme.colours.blue};
              svg{
                  color: ${({ theme }) => theme.colours.yellow};
              }
          }
      }
  }
  .fs-foter-columns{
	  display: flex;
	  justify-content: space-between;
	  ul {
		  text-align: left;

		  li {
			  margin-bottom: ${({ theme }) => theme.spacing(15)};

			  a {
				  color: #fff;
				  font-size: ${({ theme }) => theme.spacing(16)};
				  text-transform: capitalize;
				  text-decoration: none;
				  transition: all 0.4s ease-in-out;
			  }
		  }
	  }
  }
`;

export default GlobalStyles;