import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 32, left: 0, right: 0 })};
  overflow: hidden;
  z-index: ${({ theme }) => theme.zindex.scrollingText.scroll};

  .inner {
    position: relative;
    display: flex;
    align-items: center;
    width: max-content;

    span {
      margin: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 16, right: 16 })};

      @media (min-width: ${({ theme }) => theme.breakPoints.tablet}) {
        margin: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 32, right: 32 })};
      }
    }

    img {
      width: 100%;
      max-width: ${({ theme }) => `clamp(${theme.spacing(60)}, 10vw, ${theme.spacing(100)})`};
      height: auto;
      margin: ${({ theme }) => theme.spacing({ top: 0, bottom: 0, left: 16, right: 16 })};
    }
  }
`;

export const InnerWrapper = styled.div``;
