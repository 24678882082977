import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    yellow: {
      background: theme.colours.yellow,
    },
    tan: {
      background: theme.colours.tan,
    },
    blue: {
      background: theme.colours.blue,
    },
    brown: {
      background: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

export const ResultsComponent = styled.div`
  padding: ${({ theme }) => theme.spacing(60)} 0;
  background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ variant: backgroundColor, theme }).background};

  .loading-spinner {
    height: 400px;
  }

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => theme.spacing(80)} 0;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing(100)} 0;
  }
`;

export const DecorativeItem = styled.div`
  align-content: center;
  .inner-deco {
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: center;
    img{
      margin: auto;
      max-width: 178px;
      height: auto;
    }
    p{
      font-weight: 700;
      font-size: 48px;
      line-height: 52px;
      align-self: center;
      color: ${({ theme }) => theme.colours.red};
      text-transform: uppercase;
    }
    ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
      p{
        font-size: 24px;
        line-height: 28px;
      }
      img{
        max-width: 96px;
        height: auto;
      }
    }
  }
`;



export const ButtonWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(80)};

  button {
    margin: 0 auto;
  }

  .loading-spinner {
    height: 400px;
  }
`;