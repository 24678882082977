import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Icon from 'components/shared/Icon';
import {FavouritesButton} from "../MainNav/MainNav.styles";
import {isServer} from "@sitecore-jss/sitecore-jss";

const NavContainer = styled.div`
	background-color: ${({ theme }) => theme.colours.blue};
	display: flex;
	justify-content: end;
	align-items: center;
	padding: 10px 20px;
	color: white;
	position: relative;
	z-index: 991;
	border-bottom: 1px solid #66A8EE;
`;

const NavItem = styled.div`
	margin: 0 0 0 15px;
`;

const NavLink = styled(Link)`
	color: white;
	text-decoration: none;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase;
    transition: all 0.3s ease-in-out;
	${({ theme }) => theme.breakPoints.minBp('tablet')}{
		font-size: 16px;
		text-transform: uppercase;
		transition: all 0.3s ease-in-out;
    }
	&:hover {
		text-decoration: underline;
        color: #fff;
	}
`;

const SearchButton = styled.button`
	background: none;
	border: none;
	color: white;
	cursor: pointer;
    padding-right: 0;
    svg{
        color: #fff;
		transition: all 0.3s ease-in-out;
    }
    &:hover{
        svg{
            color: ${({ theme }) => theme.colours.yellow};
        }
    }
`;

const UtilityNav = ({ onSearchClick, search, sitecoreContext, setSearch }) => {
    const { site, route, globalContent } = sitecoreContext;
    const handleLanguageSelector = (newLangCode) => {
        if (!newLangCode) return;

        if (!isServer()) {
            let splitPath = window.location.pathname.split('/');
            splitPath[1] = newLangCode;
            return (window.location.href = splitPath.join('/'));
        }
    };
    const languageSelector = globalContent?.languageSelectorItems;
    return (
        <NavContainer>
            {Array.isArray(languageSelector) && languageSelector.length
                ? languageSelector.map(({ text, code }, index) => {
                    return (
                        <NavItem>
                            <NavLink
                                className="nav-end-link"
                                role="menuitem"
                                key={`languageSelector-${index}-${text}`}
                                text={text}
                                onClick={() => handleLanguageSelector(code)}
                            />
                        </NavItem>
                    );
                })
                : null}
            <NavItem>
                <NavLink to="/marketing-tools">MARKETING TOOLS</NavLink>
            </NavItem>
            <NavItem>
                <NavLink to="/about-our-beans">ABOUT OUR BEANS</NavLink>
            </NavItem>
            <NavItem>
                <>
                    <SearchButton
                        id="search-button"
                        className="search-button"
                        disabled={route?.name === 'search'}
                        aria-label="Open Search"
                        title="Open Search"
                        onClick={() => {
                            setSearch((prevState) => !prevState);
                        }}
                    >
                        <Icon className="search" icon={search ? 'cross' : 'search'} size="lg" />
                    </SearchButton>
                </>
            </NavItem>
        </NavContainer>
    );
};

export default withSitecoreContext()(UtilityNav);