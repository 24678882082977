
import React from 'react';
import PropTypes from 'prop-types';
import { ContentBlockComponent } from './ContentBlock.styles.js';

const ContentBlock = props => {
  return <ContentBlockComponent>ContentBlock PlaceHolder Content</ContentBlockComponent>
}

ContentBlock.propTypes = {

};

export default ContentBlock;
