import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/shared/JssOverrides';
import {FeaturedFactList, FeaturedFactsComponent, Wrapper} from './FeaturedFacts.styles.js';
import Icon from "../shared/Icon";

const FeaturedFacts = ({ fields = {}, params = {} }) => {
    const { title = {}, firstIcon, firstText, largeImage, secondIcon, secondText, thirdIcon, thirdText } = fields;
    const {
        showBeanBurst= '',
        invert = '',
        backgroundColor = 'tan',
        alignment = 'left',
    } = params;
    const [show, setShow] = useState(false);
    const benefits = [
        { text: firstText.value || '', icon: { url: firstIcon ? firstIcon.value.src : '', alt: firstIcon ? firstIcon.value.alt : '' } },
        { text: secondText.value || '', icon: { url: secondIcon ? secondIcon.value.src : '', alt: secondIcon ? secondIcon.value.alt : '' } },
        { text: thirdText.value || '', icon: { url: thirdIcon ? thirdIcon.value.src : '', alt: thirdIcon ? thirdIcon.value.alt : '' } },
    ];
    return (
        <FeaturedFactsComponent backgroundColor={backgroundColor} alignment={alignment}>
            <Wrapper>
                <h2>
                    {(showBeanBurst === 'true' || showBeanBurst === '1') && <Icon icon="shine" className="bean-burst"/>}
                    {title && <span dangerouslySetInnerHTML={{__html: title.value}}/>}
                </h2>
                <FeaturedFactList>
                    {benefits && benefits.map((benefit, index) => (
                        <li className="benefit-icon" key={`ingredientBenefits-${index}`}>
                            {benefit.icon && benefit.icon.url && <img src={benefit.icon.url} alt={benefit.icon.alt}/>}
                            {benefit.text && <h3>{benefit.text}</h3>}
                        </li>
                    ))}
                </FeaturedFactList>
            </Wrapper>

        </FeaturedFactsComponent>
    );
};

FeaturedFacts.propTypes = {
    fields: PropTypes.shape({
        largeImage: PropTypes.object,
        firstIcon: PropTypes.object,
        secondIcon: PropTypes.object,
        thirdIcon: PropTypes.object,
        title: PropTypes.string,
        firstText: PropTypes.string,
        secondText: PropTypes.string,
        thirdText: PropTypes.string,
    }),
    params: PropTypes.shape({
        invert: PropTypes.bool,
        showBeanBurst: PropTypes.bool,
        backgroundColor: PropTypes.string,
        alignment: PropTypes.string,
    }),
};

export default FeaturedFacts;