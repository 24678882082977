import styled from 'styled-components';

export const InRecipeProductsFoodservicesComponent = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 40, left: 0, right: 0, bottom: 40 })};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing({ top: 120, left: 0, right: 0, bottom: 80 })};
  }

  > h2 {
    margin: 0 ${({ theme }) => theme.spacing(64)};
    margin-bottom: ${({ theme }) => theme.spacing(20)};
    color: ${({ theme }) => theme.colours.brown};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-transform: uppercase;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(32)};
    }
  }
    
  .loading-spinner {
    max-height: 200px;
  }
  .button-container{
    display: flex;
    justify-content: center;
  }
`;

export const ProductList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    margin-bottom: ${({ theme }) => theme.spacing(48)};
  }

  li {
    display: flex;
    flex-direction: column;
    margin: ${({ theme }) => theme.spacing({ top: 0, left: 16, right: 16, bottom: 16 })};
    overflow: hidden;

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-width: 200px;
      margin: ${({ theme }) => theme.spacing({ top: 0, left: 32, right: 32, bottom: 0 })};
    }
    .image-wrapper{
      width: 280px;
      height: 280px;
      display: flex;
      justify-content: center;
      background-color: ${({ theme }) => theme.colours.yellow};
    }
    img {
      display: block;
      max-width: 96px;
      margin: 0 auto ${({ theme }) => theme.spacing(12)};
      object-fit: contain;
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-width: 160px;
      }
      transition: all 0.4s ease-in-out;
    }

    a {
      position: relative;
      text-decoration: none;

      .recipe-card-badge {
        position: absolute;
        width: 50px;
        top: -4px;
        left: 0;

        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          top: -5px;
          left: 0px;
          width: 80px;
        }
      }
      &:hover{
        .image-wrapper{
          img{
            scale: 1.1;
          }
        }
      }
    }

    h4 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colours.blue};
      text-transform: capitalize;
      margin-top: 20px;
    }
    .h4 {
      margin-bottom: 0;
      color: ${({ theme }) => theme.colours.blue};
      text-transform: capitalize;
      margin-top: 20px;
    }
  }
`;