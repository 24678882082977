import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    ProductDescriptionWrapper,
    ProductDescriptionInner,
    ProductDetailBox,
    DetailBoxList
} from './ProductDescription.styles.js';
import DownloadSvg from "../RelatedCarouselSlide/svg/DownloadSvg.jsx";


const ProductDescription = (
    {
        title,
        backgroundColor,
        className,
        field,
        tag,
        editable,
        sitecoreContext,
        id,
        ...props
    }
) => {
    const {fields = {}, params = {}} = props;
    const {globalContent = {}} = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();
    const { site } = sitecoreContext;
    const isFoodserviceSite = site.name === 'bushs-beans-foodservices';

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <ProductDescriptionWrapper backgroundColor={backgroundColor}  className={`product-detail ${isFoodserviceSite ? 'fs-product' : ''}`} id={id}>
                <ProductDescriptionInner>
                    <div className="container" style={{ minHeight: '200px', width: '100%', display: 'block' }}>
                        <h1>Product Description Module</h1>
                        <div>Product description content</div>
                    </div>
                </ProductDescriptionInner>
            </ProductDescriptionWrapper>
        );
    }

    return (
        <ProductDescriptionWrapper backgroundColor={backgroundColor}  className={`product-detail ${isFoodserviceSite ? 'fs-product' : ''}`} id={id}>
            <ProductDescriptionInner>
                <div className="container">
                    <div className="col product-description">
                        <h2>PRODUCT DESCRIPTION</h2>
                        <p>The silky texture and nutty flavor works great in pasta dishes, salads,
                            and soups like minestrone. Plus, they’re low sodium, making them a
                            perfect choice when trying to meet nutritional guidelines or satisfy
                            eaters looking for healthier meals.</p>
                        <a href="#" className="download-link">
                            <span className="link-text">NUTRITIONAL SHEET</span>
                            <span className="circle">
                                <DownloadSvg className="downloadSvg"/>
                            </span>
                        </a>
                        <a href="#" className="download-link">
                            <span className="link-text">SELL SHEET</span>
                            <span className="circle">
                                <DownloadSvg className="downloadSvg"/>
                            </span>
                        </a>
                        <a href="#" className="download-link">
                            <span className="link-text">PRODUCT FORMULATION SHEET</span>
                            <span className="circle">
                                <DownloadSvg className="downloadSvg"/>
                            </span>
                        </a>
                    </div>
                    <div className="col product-details">
                        <ProductDetailBox>
                            <h3>PRODUCT DETAILS</h3>
                            <DetailBoxList>
                                <div className="info-cols">
                                    <div className="product-details-item">
                                        <span className="label">UPC #</span>
                                        <span className="value">1873</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">NET WEIGHT</span>
                                        <span className="value">111 oz</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">DRAIN WEIGHT</span>
                                        <span className="value">80 grams (4.6oz)</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">SERVING SIZE</span>
                                        <span className="value">130 grams (4.6 oz)</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">SERVINGS/CONTAINER</span>
                                        <span className="value">24</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">PALLET CONFIG.</span>
                                        <span className="value">56cs/pallet</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">CASE PACK</span>
                                        <span className="value">6/111 oz.</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">CASE WEIGHT</span>
                                        <span className="value">46.4</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">SHELF LIFE</span>
                                        <span className="value">2 Years</span>
                                    </div>
                                    <div className="product-details-item">
                                        <span className="label">STORAGE</span>
                                        <span className="value">35-95°F Dry, ambient air</span>
                                    </div>
                                </div>
                                <div className="product-details-item-full-width">
                                    <span className="label">INGREDIENTS:</span>
                                    <span className="value">Prepared cannellini beans, water, salt, calcium disodium EDTA (to promote color retention).</span>
                                </div>
                            </DetailBoxList>
                        </ProductDetailBox>
                    </div>
                </div>
            </ProductDescriptionInner>
        </ProductDescriptionWrapper>
    );
};

ProductDescription.propTypes = {
    sitecoreContext: PropTypes.object,

};


export default withSitecoreContext()(ProductDescription);