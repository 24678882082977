import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';

import { SubscribeComponent, Error, Success } from './Subscribe.style.js';
import { useDeviceResize } from 'hooks';
import { postData } from 'helpers/requestHelpers';
import { formConfig } from 'helpers/formHelpers';

const Subscribe = ({ placeholder = 'enter your email', success, error: errorMessage }) => {
  const device = useDeviceResize();
  const [subscribe, setSubscribe] = useState('');
  const [error, setError] = useState(null);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const emailRegex = /.*@.*\..*/;
  const { endpoint, key } = formConfig({ type: 'subscribe' });

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const newsletterForm = document.getElementById('newsletterForm');
    if (newsletterForm) {
      const nlFormInput = document.getElementById('nlforminput');
      if (nlFormInput) {
        nlFormInput.addEventListener('change', handleInputChange);
      }
    }
  }, []);

  const handleInputChange = (event) => {
    const inputValue = event.target.value || document.getElementById('nlforminput').value;
    setSubscribe(inputValue);
    console.log('Input value:', inputValue);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setError(null);
    if (!endpoint) return;

    if (emailRegex.test(subscribe)) {
      postData({ endpoint, key, data: { emailAddress: subscribe } })
          .then((res) => {
            if (res.status === 200) setSuccessfullySubmitted(true);
          })
          .catch((error) => {
            console.error(error);
            setError('Failed to send request');
          });
    } else {
      setError(errorMessage);
    }
  };

  return (
      <SubscribeComponent>
        <form id="subscribeNL" onSubmit={handleSubmit}>
          <div>
            {successfullySubmitted ? (
                <Success>
                  {success}
                  <Icon icon="tick" size="xl" />
                </Success>
            ) : (
                <>
                  <input
                      type="text"
                      id="subscribe"
                      name="subscribe"
                      aria-label="Subscribe For News"
                      placeholder={placeholder}
                      value={subscribe}
                      onChange={handleInputChange}
                  />
                  {device !== '!browser' && (
                      <Button id="subscribesubmit" ariaLabel="Submit" title="Click to submit email address" type="circle" icon="chevron-right" size={device === 'desktop' ? 'large' : 'small'} />
                  )}
                </>
            )}
          </div>
          {error && <Error>* {errorMessage}</Error>}
        </form>
      </SubscribeComponent>
  );
};

Subscribe.propTypes = {
  placeholder: PropTypes.string,
  success: PropTypes.string,
  error: PropTypes.string,
};

export default Subscribe;