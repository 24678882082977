import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.yellow,
    },
    yellow: {
      background: theme.colours.red,
    },
    blue: {
      background: theme.colours.yellow,
    },
    brown: {
      background: theme.colours.yellow,
    },
    tan: {
      background: theme.colours.tan,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const SideBySideComponent = styled.section`
 
  background-color: ${({ theme, variant, backgroundColor }) =>
    theme.colours.checkColourExists(backgroundColor) || getVariantColours({ theme, variant }).background};
  &.bg-tan{
    background-color: ${({ theme }) => theme.colours.tan};
  }
  &.bg-blue{
    background-color: ${({ theme }) => theme.colours.blue};
  }
  &.bg-brown{
    background-color: ${({ theme }) => theme.colours.blue};
  }
  &.bg-transparent{
    background-color: transparent;
  }
  ${({ backgroundImage }) =>
    backgroundImage?.src
      ? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `
      : ''}
  .inner{
       display: flex;
    flex-direction: column-reverse;
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      flex-direction: row;
  
      ${({ alignment }) => `
        ${alignment === 'right' ? 'flex-direction: row-reverse;' : ''}
      `}
    }
  
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => `clamp(${theme.spacing(50)}, 5vw, ${theme.spacing(100)});`};
    }
  
    ${({ theme }) => theme.breakPoints.minBp('1920px')} {
      padding: ${({ theme }) => `clamp(${theme.spacing(50)}, 6vw, ${theme.spacing(100)});`};
    }
  
    > div {
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        flex: 1;
  
        ${({ alignment }) => `
        ${
    alignment === 'center'
        ? `width: 50%;
        display: block;
        flex: none;
        margin: 0 auto;`
        : ''
        }
      `}
      }
    } 
  }
  .container{
    display: flex;
    flex-direction: column-reverse;
    width: 1550px;
    margin: auto;
    padding: 0;
    max-width: 100%;
    &.no-bg{
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 80px;
      margin-bottom: 80px;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-top: 160px;
        margin-bottom: 160px;
        max-width: 95%;
      }
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: clamp(3.125rem, 5vw, 6.25rem) 0;
      max-width: 95%;
    }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      flex-direction: row;
  
      ${({ alignment }) => `
        ${alignment === 'right' ? 'flex-direction: row-reverse;' : ''}
      `}
    }
  
    > div {
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        flex: 1;
  
        ${({ alignment }) => `
        ${
    alignment === 'center'
        ? `width: 50%;
        display: block;
        flex: none;
        margin: 0 auto;`
        : ''
}
      `}
      }
    } 
  }
  &.hero-bg-lg{
    margin-bottom: 0;
    @media (max-width: 1100px){
      flex-direction: column;
    }
    .cont-img-block{
      display: none;
    }
    @media (max-width: 1100px){
      .cont-img-block{
        display: flex;
        order: 0;
      }
    }
    .promoboxWrap{
      order: 1;
    }
    .promo-box{
      background: ${({ theme }) => theme.colours.red};
      height: fit-content;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 35px;
      &.bg-tan{
        background-color: ${({ theme }) => theme.colours.tan};
      }
      &.bg-blue{
        background-color: ${({ theme }) => theme.colours.blue};
      }
      &.bg-brown{
        background-color: ${({ theme }) => theme.colours.blue};
      }
      &.bg-transparent{
        background-color: transparent;
      }
      h1{
        margin-bottom: 20px;
        display: block;
        font-size: 35px;
        line-height: 1.2;
        font-weight: 900;
      }
      .h1{
        margin-bottom: 20px;
        text-align: left;
        display: block;
        font-size: 35px;
        line-height: 1.2;
        font-weight: 900;
      }
      p{
        margin-bottom: 20px;
        display: block;
        width: 100%;
        text-align: center;
        display: block;
      }
      .p{
        margin-bottom: 20px;
        display: block;
        width: 100%;
        text-align: center;
        display: block;
      }
      a{
        margin: 0 auto;
      }
      @media (max-width: 1100px){
        .h1{
            text-align: center;
            font-size: 26px;
        }
        h1{
          text-align: center;
          font-size: 26px;
        }
      }
      @media (min-width: 1100px){
          width: 500px;
          margin: 100px 15px;
          display: block;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          height: auto;
        text-align: left;
        h1{
          text-align: left;
          font-weight: 900;
        }
        p{
          text-align: left;
        }
        .p{
          text-align: left;
        }
        a{
          margin: 0 ;
        }
      }
    }
  }
`;

export const Block = styled.div`
  position: relative;
  overflow: hidden;

  > img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .promo-box {
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      height: 100%;
      &.text-align-left{
        h2, .h1{
          text-align: left;
        }
        p, .p{
          text-align: left;
        }
        .button-link{
          margin: 0 auto 0 0;
        }
      }
      &.text-align-right{
        h2, .h1{
          text-align: right;
        }
        p, .p{
          text-align: right;
        }
        .button-link{
          margin: 0 0 0 auto;
        }
      }
      &.text-align-center{
        h2, .h1{
          text-align: center;
        }
        p, .p{
          text-align: center;
        }
        .button-link{
          margin: 0 auto;
        }
      }
    }
    p{
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;