import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import {
  InRecipeProductsFoodservicesComponent,
  ProductList,
  FloatingImage,
} from './InRecipeProductsFoodservices.styles.js';

import Link from 'components/shared/Link';
import LoadingSpinner from 'components/shared/LoadingSpinner';

import { ConditionalComponentWrapper } from 'helpers/componentHelpers';
import translate from 'helpers/translate';
import { searchConfig, globalizationConfig } from 'helpers/searchHelpers';

import { useFetch } from 'hooks';
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';
import { checkBadges, languageFilter } from 'helpers/badgeHelpers.js';
import Button from "../shared/Button";

const InRecipeProductsFoodservices = ({ sitecoreContext = {}, fields = {}, type = 'ids' }) => {
  const { viewBag = {}, trademarkReplacements = [], site } = sitecoreContext;
  const { featuredProductPages = [] } = fields;
  const { endpoint } = searchConfig({ type });

  const [state, setState] = useState({
    results: [],
    ids: [...featuredProductPages],
    globalization: globalizationConfig(),
    sortOrder: 'dateDesc',
  });

  const { results, ids, globalization } = state;

  const { country = '', language = '' } = globalization;

  const { data, loading, error } = useFetch(endpoint, {
    method: 'POST',
    body: {
      subject: {
        ids,
      },
      context: {
        globalization,
        request: {
          scheme: 'https',
          queryString: '',
          routeData: {
            site: site.name
          },
        },
        content: {},
      },
    },
  });

  useEffect(() => {
    if (data) {
      setState((oldState) => {
        return {
          ...oldState,
          results: [...data?.subject?.results],
        };
      });
    }
  }, [data]);

  return (
      <InRecipeProductsFoodservicesComponent>
        <h2>Products Featured</h2>
        {Array.isArray(results) && results.length > 0 ? (
            <ProductList>
              {results?.map((product, index) => {
                const {
                  searchTitle: title = '',
                  searchImage: image = {},
                  itemUrl: link = '',
                  productIsNew = false,
                  webBadge = '',
                  searchDescription: description = '',
                } = product;

                const filteredLanguage = languageFilter(language);
                const activeImgSrc = checkBadges(productIsNew, webBadge, filteredLanguage, country);

                return (
                    <li key={`recipe-product-promo-${index}`}>
                      <ConditionalComponentWrapper
                          condition={link}
                          wrapper={(children) => (
                              <Link href={link} class={null}>
                                {activeImgSrc && (
                                    <img className="recipe-card-badge" src={activeImgSrc} alt={'New Look'}/>
                                )}
                                {children}
                              </Link>
                          )}
                      >
                        <div className="image-wrapper">
                        {image?.url && <img src={image?.url} alt={image?.alt}/>}
                        </div>
                        {title && (
                            <h3
                                className="h4"
                                dangerouslySetInnerHTML={{
                                  __html: trademarkFilterHelper(title, trademarkReplacements),
                                }}
                            />
                        )}
                        {description && (
                            <p
                                className="desc"
                                dangerouslySetInnerHTML={{
                                  __html: trademarkFilterHelper(description, trademarkReplacements),
                                }}
                            />
                        )}
                      </ConditionalComponentWrapper>
                    </li>
                );
              })}
            </ProductList>
        ) : (
            <LoadingSpinner/>
        )}
        <div className="button-container">
          <Button id="related-pages-btn" target="_blank" text="View All Products" href="/products"
                  variant="yellowBrown"/>
        </div>
      </InRecipeProductsFoodservicesComponent>
  );
};

InRecipeProductsFoodservices.propTypes = {};

export default withSitecoreContext()(InRecipeProductsFoodservices);