import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      title: theme.colours.yellow,
      text: '#fff',
    },
    yellow: {
      background: theme.colours.yellow,
      title: theme.colours.red,
      text: theme.colours.adaBrown,
    },
    blue: {
      background: theme.colours.blue,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    brown: {
      background: theme.colours.brown,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    transparent: {
      background: 'transparent',
    },
    tan: {
      background: 'transparent',
      title: theme.colours.blue,
      text: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const PromoBoxComponent = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 55, bottom: 55, left: 35, right: 35 })};
  color: ${({ theme }) => theme.colours.yellow};
  text-align: center;
  background-color: ${({ theme, variant, backgroundColor }) =>
    theme.colours.checkColourExists(backgroundColor) || getVariantColours({ theme, variant }).background};

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) =>
      `clamp(${theme.spacing(80)}, 6vw, ${theme.spacing(100)}) 
      clamp(${theme.spacing(48)}, 6vw, ${theme.spacing(100)})`};
  }

  h1 {
    color: ${({ theme, variant, titleColor }) =>
      theme.colours.checkColourExists(titleColor) || getVariantColours({ theme, variant }).title};
    font-weight: 400;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
  }

  .h1 {
    color: ${({ theme, variant, titleColor }) =>
        theme.colours.checkColourExists(titleColor) || getVariantColours({ theme, variant }).title};
    font-weight: 400;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(44)};
    }
    &.brown{
      color: ${({ theme }) => theme.colours.brown};
    }
    &.blue{
      color: ${({ theme }) => theme.colours.blue};
    }
    &.tan{
        color: ${({ theme }) => theme.colours.tan};
    }
  }

  p {
    color: ${({ theme, variant, copyColor }) =>
      theme.colours.checkColourExists(copyColor) || getVariantColours({ theme, variant }).text};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: 90%;
      margin: 0 auto ${({ theme }) => theme.spacing(90)};
    }
    &.brown{
      color: ${({ theme }) => theme.colours.brown};
    }
    &.blue{
      color: ${({ theme }) => theme.colours.blue};
    }
    &.tan{
      color: ${({ theme }) => theme.colours.tan};
    }
  }
  .p {
    color: ${({ theme, variant, copyColor }) =>
        theme.colours.checkColourExists(copyColor) || getVariantColours({ theme, variant }).text};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      width: 100%;
      font-size: clamp(1rem, 4vw, 1.5rem);
      line-height: clamp(1.5rem, 4vw, 2rem);
    }
    &.brown{
      color: ${({ theme }) => theme.colours.brown};
    }
    &.blue{
      color: ${({ theme }) => theme.colours.blue};
    }
    &.tan{
      color: ${({ theme }) => theme.colours.tan};
    }
  }

  > button {
    margin: 20px auto 0 auto;
  }

  > a {
    display: block;
    width: fit-content;
    margin: 0 auto;
  }
  &.fs-promobox{
    h2, .h1{
      line-height: clamp(2.125rem, 4vw, 3.75rem);
      font-size: clamp(2rem, 4vw, 3.5rem);
      font-weight: 900;  
    }
  }
`;