import React from 'react';
import { useDeviceResize } from 'hooks';
import { isServer } from '@sitecore-jss/sitecore-jss';
import Button from 'components/shared/Button';
import { Text } from 'components/shared/JssOverrides';
import {withSitecoreContext, isExperienceEditorActive, Image} from '@sitecore-jss/sitecore-jss-react';
import InlineSVG from 'react-inlinesvg';
import DownloadSvg from "./svg/DownloadSvg.jsx";

import {
    CardComponent,
    ProductCardComponent,
    RelatedCarouselSlideComponent,
    RelatedSlideControls
} from './RelatedCarouselSlide.styles.js';
import {ConditionalComponentWrapper} from "../../helpers/componentHelpers";
import Link from "../shared/Link";
import Icon from "../shared/Icon";

const RelatedCarouselSlide = ({ sitecoreContext, type, icon, background, setCurrentSlide, totalSlides, rendering, ...props }) => {
    const fields = rendering?.fields;
    const params = rendering?.params;
    const device = useDeviceResize();
    const experienceEditor = isExperienceEditorActive();

    // console.log('related slide fields', fields);
    // console.log('related slide props', props);
    // console.log('related slide params', params);
    // console.log('related slide rendering', rendering);

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <RelatedCarouselSlideComponent className={`slide bg-${background}`}>
                <div className="related-content">
                    <h3>Related Carousel Slide</h3>
                    <div style={{ display: 'flex' }}>
                        <div>
                            {fields?.title?.value && <Text tag="h3" field={fields?.title} />}
                            {fields?.copy?.value && <Text tag="p" field={fields?.copy} />}
                        </div>
                        <div>
                            {fields?.image?.value?.src && (
                                <img src={fields?.image?.value?.src} alt={fields?.image?.value?.alt || ''} />
                            )}
                        </div>
                        <div>
                            {fields?.cta?.value?.href && (
                                <Button href={fields?.cta?.value?.href} text={fields?.cta?.value?.text || 'Learn More'} />
                            )}
                        </div>
                    </div>
                </div>
            </RelatedCarouselSlideComponent>
        );
    }

    return (
        <RelatedCarouselSlideComponent className="slide">
            {type === 'product' && (
            <ProductCardComponent type={type} className={`poduct-card bg-${background}`}>
                <ConditionalComponentWrapper
                    condition={fields?.cta?.value?.href}
                    wrapper={children => (
                        <Link {...fields?.cta?.value} className="button-link">
                            {children}
                        </Link>
                    )}
                >
                    <div className="image-container">
                        {fields?.image && <Image field={fields?.image}/>}
                    </div>
                    <footer>
                        {fields?.title?.value && (
                            <h3>
                                {fields?.title?.value}
                            </h3>
                        )}
                        {fields?.copy?.value && (
                            <p>
                                {fields?.copy?.value}
                            </p>
                        )}
                    </footer>
                </ConditionalComponentWrapper>
            </ProductCardComponent>
            )}
            {type === 'page' && (
            <CardComponent className={`page-card bg-${background}`}>
                <ConditionalComponentWrapper
                    condition={fields?.cta?.value?.href}
                    wrapper={children => (
                        <Link {...fields?.cta?.value} className="button-link">
                            {children}
                        </Link>
                    )}
                >
                    <div className="image-container">
                        {fields?.image && <Image field={fields?.image}/>}
                    </div>
                    <footer>
                        {fields?.title?.value && (
                            <h3>
                                {fields?.title?.value}
                                <span className="circle">
                                  <Icon icon="chevron-right" size="small"/>
                                </span>
                            </h3>
                        )}
                    </footer>
                </ConditionalComponentWrapper>
            </CardComponent>
            )}
            {type === 'resource' && (
                <CardComponent className={`page-card bg-${background}`}>
                    <ConditionalComponentWrapper
                        condition={fields?.cta?.value?.href}
                        wrapper={children => (
                            <Link {...fields?.cta?.value} className="button-link">
                                {children}
                            </Link>
                        )}
                    >
                        <div className="image-container">
                            {fields?.image && <Image field={fields?.image}/>}
                        </div>
                        <footer className="card-footer-solid">
                            {fields?.title?.value && (
                                <h3>
                                    {fields?.title?.value}
                                    <span className="circle">
                                    <DownloadSvg className="downloadSvg" />
                                    </span>
                                </h3>
                            )}
                        </footer>
                    </ConditionalComponentWrapper>
                </CardComponent>
            )}
        </RelatedCarouselSlideComponent>
    );
};

RelatedCarouselSlide.propTypes = {};

export default withSitecoreContext()(RelatedCarouselSlide);