import styled from 'styled-components';
import { hexToRgba } from 'helpers/colorHelpers';
import BeanBurstBg from 'assets/images/bean_burst_faint_br.png';


export const TestimonialCarouselComponent = styled.section`
	position: relative;
	min-height: ${({ theme }) => theme.spacing(400)};
	overflow: hidden;
	padding: 100px 0;
	background-image: url(${BeanBurstBg});
	background-repeat: no-repeat;
	background-position: center top;
	background-size: contain;
	display: flex;
	flex-flow: column;
	justify-content: center;
	@media (max-width: 1024px){
        min-height: ${({ theme }) => theme.spacing(300)};
    }
	.slick-slider {
		.slick-dots {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 16px;
			bottom: -50px;
			li {
				button {
					width: 16px;
					height: 16px;
					border: 2px solid ${({theme}) => theme.colours.red};
					border-radius: 50%;
					transition: all 0.4s ease-in-out;

					&:hover {
						border-color: ${({theme}) => theme.colours.brown};
					}

					&:before {
						display: none;
					}
				}

				&.slick-active {
					button {
						background-color: ${({theme}) => theme.colours.red};
						border: 2px solid ${({theme}) => theme.colours.red};

						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
    &.page-type-article{
        background-image: none;
        padding: 40px 0;
        min-height: auto;
        .testimonial-wrapper{
            width: 840px;
            .testimonial-content{
				width: 90%;
                .title-heading{
                    font-size: 40px;
                    color: #1471D2;
				}
                .quote{
                    font-size: 20px;
                    line-height: 1.5;
                    color: #D43631;
				}
            }
            @media (max-width: 900px){
                width: 90%;
                .testimonial-content{
                    .title-heading{
                        font-size: 32px;
                    }
                    .quote{
                        font-size: 16px;
                    }
                
                }
            }
        }
    }
`;

export const TestimonialCarouselControls = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    top: 50%;
    z-index: 3;
    display: flex;
    justify-content: space-between;
	width: 1500px;
	max-width: 100%;
	margin: auto;
    .prev{
        position: absolute;
        left: 0;
    }
    .next{
      position: absolute;
      right: 0;
    }
    @media (max-width: 980px){
        display: none;
    }
`;

export const PlaceholderWrapper = styled.div`
    position: relative;
    display: block;
    min-height: 300px;
`;