import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    yellow: {
      background: theme.colours.yellow,
      title: theme.colours.red,
      text: theme.colours.adaBrown,
    },
    blue: {
      background: theme.colours.blue,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    brown: {
      background: theme.colours.brown,
      title: theme.colours.yellow,
      text: theme.colours.white,
    },
    transparent: {
      background: 'transparent',
    },
  };

  if (variant in variants) return variants[variant];

  return variants.red;
};

export const FeaturedFactsComponent = styled.section`
  padding: ${({ theme }) => `${theme.spacing(0)} ${theme.spacing(35)} ${theme.spacing(0)}`};
  background-color: ${({ theme }) => theme.colours.tan};
  margin: 80px auto;
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    margin: 160px auto;
  }
  &.benefit-list-bushs-beans-foodservices{
    h2{
      color: ${({ theme }) => theme.colours.brown};
    }
    ul{
      li{
        border-bottom: ${({ theme }) => `4px solid ${theme.colours.brown}`};
        h3{
          color: ${({ theme }) => theme.colours.brown};
          span{
            display: block;
            color: ${({ theme }) => theme.colours.brown};
          }
        }
        svg{
          color: ${({ theme }) => theme.colours.blue};
        }
      }
    }
  }
  &#invert {
    background-color: ${({ theme }) => theme.colours.brown};

    h2 {
      color: ${({ theme }) => theme.colours.tan};
    }

    ul {
      li {
        border-bottom: ${({ theme }) => `4px solid ${theme.colours.yellow}`};

        h3 {
          color: ${({ theme }) => theme.colours.yellow};
        }
      }
    }
  }
`;

export const FeaturedFactList = styled.ul`
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex: 1;
    }

    li {
      display: flex;
      align-items: center;
      margin-bottom: ${({ theme }) => theme.spacing(20)};
      padding-bottom: ${({ theme }) => theme.spacing(20)};
      border-bottom: ${({ theme }) => `4px solid ${theme.colours.brown}`};

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
      &.benefit-icon{
        &:last-of-type{
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
      &.benefit-icon {
        svg {
          min-width: 58px;
          height: auto;
          color: ${({ theme }) => theme.colours.blue};

          ${({ theme }) => theme.breakPoints.minBp('desktop')} {
            min-width: 78px;
          }
        }
      }
      svg{
        color: ${({ theme }) => theme.colours.blue};
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-bottom: ${({ theme }) => theme.spacing(30)};
        padding-bottom: ${({ theme }) => theme.spacing(30)};
      }

      h3 {
        margin: ${({ theme }) => `0 0 0 ${theme.spacing(20)}`};
        color: ${({ theme }) => theme.colours.brown};
        font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 3vw, ${theme.spacing(40)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(29)}, 4vw, ${theme.spacing(48)})`};
        text-align: left;

        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          margin: ${({ theme }) => `0 0 0 ${theme.spacing(30)}`};
        }
        span{
          display: block;
          color: ${({ theme }) => theme.colours.brown};
        }
      }
    }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    flex-direction: row;
    align-items: center;
  }
  .bean-burst{
    color: ${({ theme }) => theme.colours.brown};
  }
  h2 {
    color: ${({ theme }) => theme.colours.brown};
    font-size: ${({ theme }) => `clamp(${theme.spacing(54)}, 6vw, ${theme.spacing(128)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(62)}, 6vw, ${theme.spacing(136)})`};
    text-transform: uppercase;
    span{
      display: block;
    }
    u{
      text-decoration: underline;
    }
    svg{
      display: block;
      margin: auto;
      width: 150px;
      height: 50px;
      position: relative;
      top: -15px;
      color: ${({ theme }) => theme.colours.brown};
    }
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      flex: 1;
      margin-right: ${({ theme }) => theme.spacing(75)};
    }
  }

  .disclaimer {
    display: block;
    margin-top: ${({ theme }) => theme.spacing(32)};
    font-size: 1rem;
    text-align: left;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-left: ${({ theme }) => theme.spacing(110)};
    }
  }
`;