import styled from 'styled-components';

export const SelectComponent = styled.div`
  position: relative;

  & label {
    position: relative;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.spacing(12)};
    color: ${({ theme }) => theme.colours.adaBrown};
    margin-left: ${({ theme }) => theme.spacing(20)};
  }

  & select {
    height: ${({ theme }) => theme.spacing(42)};
    width: 100%;
    font-size: ${({ theme }) => theme.spacing(14)};
    color: ${({ theme, touched }) => (touched ? theme.colours.adaBrown : '#808080')};
    font-weight: 600;
    border: none;
    background-color: ${({ theme }) => theme.colours.white};
    border-radius: ${({ theme }) => theme.spacing(100)};

    margin: 0;
    padding-left: 18px;
    box-sizing: border-box;
    appearance: none;
    cursor: pointer;

    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px ${({ theme }) => theme.colours.blue};
    }
  }

  & .error-message {
    margin-top: ${({ theme }) => theme.spacing(10)};
    text-align: left;
    font-size: ${({ theme }) => theme.spacing(10)};
    line-height: ${({ theme }) => theme.spacing(16)};
    color: #ef3826;
    margin-left: ${({ theme }) => theme.spacing(20)};
    margin-bottom: ${({ theme }) => theme.spacing(0)};
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;
export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colours.red};
  border-radius: 0 25px 25px 0;
  transform: translate(0%, -50%);
  pointer-events: none;

  svg {
    position: relative;
    top: 1px;
    right: 2px;
    color: ${({ theme }) => theme.colours.tan};
  }
`;

export const IconWrapperFs = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 42px;
  height: 42px;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colours.blue};
  border-radius: 0 25px 25px 0;
  transform: translate(0%, -50%);
  pointer-events: none;

  svg {
    position: relative;
    top: 1px;
    right: 2px;
    color: ${({ theme }) => theme.colours.tan};
  }
`;