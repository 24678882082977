import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { ContactRepFormComponent, FormContent, ContactUsCardsContainer } from './ContactRepForm.styles.js';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text, RichText } from 'components/shared/JssOverrides';

import ContactRepHero from './components/ContactRepHero.jsx';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import Textarea from 'components/shared/Textarea';
import Button from 'components/shared/Button';
import Grid from 'components/shared/Grid';
import Spinner from 'components/shared/LoadingSpinner';

import translate from 'helpers/translate';
import { useForm } from 'react-hook-form';

import { useFetch } from 'hooks';
import { searchConfig } from 'helpers/searchHelpers';
import { zipCodeRegex } from 'helpers/regexHelpers';

// TODO: form endpoint needs hooking up with success/error state
const ContactRepForm = ({ fields, sitecoreContext: { viewBag, ...context }, rendering, ...props }) => {
  const { handleSubmit, register, errors, setValue, getValues, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const { endpoint } = searchConfig({ type: 'contactForm' });
  const [formSubject, setFormSubject] = useState(null);
  const formRef = useRef(null);
  const [requestBody, setRequestBody] = useState(null);

  const handleReset = () => {
    reset();

    if (formRef?.current) {
      const selects = formRef.current.querySelectorAll('select');
      selects.forEach(select => (select.selectedIndex = 0));
    }
  };

  const { data, loading, error, fetchLazy } = useFetch(
      undefined,
      {
        method: 'POST',
        ...(requestBody ? { body: { ...requestBody } } : {}),
      },
      'text'
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (new URL(window.location.href).searchParams.get('ref') === 'privacy') {
        const subject = fields?.subjectOptions.find(({ value }) => value === 'Privacy Request');
        setFormSubject(subject);
      }
    }
  }, []);

  if (error) {
    return (
        <ContactRepFormComponent>
          {fields?.title?.value && (
              <ContactRepHero>
                <Text tag="h1" className="main-title" field={fields?.title} />
              </ContactRepHero>
          )}
          <div className="failure-message">
            {fields?.failureTitle && <Text tag="h2" field={fields?.failureTitle} />}
            {fields?.failureMessage && <RichText tag="p" field={fields?.failureMessage} />}
          </div>
        </ContactRepFormComponent>
    );
  }

  if (data === 'Submission successful.') {
    return (
        <ContactRepFormComponent>
          {fields?.title?.value && (
              <ContactRepHero>
                <Text tag="h1" className="main-title" field={fields?.title} />
              </ContactRepHero>
          )}
          <div className="success-message">
            {fields?.successTitle && <Text tag="h2" field={fields?.successTitle} />}
            {fields?.successMessage && <RichText tag="p" field={fields?.successMessage} />}
            <div className="contact-form-divider" />
            {fields?.privacyPolicyText && <RichText tag="p" field={fields?.privacyPolicyText} />}
          </div>
        </ContactRepFormComponent>
    );
  }

  return (
      <ContactRepFormComponent>
        {fields?.title?.value && (
            <ContactRepHero>
              <Text tag="h1" className="main-title" field={fields?.title} />
            </ContactRepHero>
        )}
        {loading ? (
            <Spinner />
        ) : (
            <form
                ref={formRef}
                onSubmit={handleSubmit(async data => {
                  setRequestBody({
                    subject: fields?.subjectOptions[Number(data.subjectLabel) - 1]?.value || 'Contact a Representative',
                    firstName: data.firstName,
                    lastName: data.lastName,
                    emailAddress: data.email,
                    telephoneNo: data?.telephone || '',
                    addressLine1: data?.mailingAddress || '',
                    addressLine2: data?.mailingAddressTwo || '',
                    city: data?.city || '',
                    country: fields?.countryOptions[Number(data.countryOptions) - 1]?.value || 'USA',
                    stateProvince: fields?.stateOrProvinceOptions[Number(data.stateOrProvinceOptions) - 1]?.value || '',
                    zipCode: data?.zip || '',
                    message: data?.yourMessage || '',
                  });
                  fetchLazy(endpoint);
                })}
            >
              <FormContent>
                {fields?.introCopy && (
                    <div className="intro-copy">
                      <Text tag="p" field={fields?.introCopy} />
                    </div>
                )}
                <div className="subject-section">
                  {fields?.subjectSectionTitle && (
                      <Text tag="h2" className="require-title" field={fields?.subjectSectionTitle} />
                  )}
                  {translate(viewBag, 'BushsBeans.Forms.ContactUs.Other.RequiredHelperText') && (
                      <p className="require-title">
                        {translate(viewBag, 'BushsBeans.Forms.ContactUs.Other.RequiredHelperText')}
                      </p>
                  )}
                  <Select
                      required
                      label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SubjectLabel')}
                      placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SubjectPlaceholder')}
                      options={[
                        { value: 'General Inquiry', label: 'General Inquiry' },
                        { value: 'Praise', label: 'Praise' },
                        { value: 'Product Feedback', label: 'Product Feedback' },
                        { value: 'Suggestion', label: 'Suggestion' },
                        { value: 'Privacy Request', label: 'Privacy Request' },
                        { value: 'Other', label: 'Other' },
                      ]}
                      error={errors?.subjectLabel?.message}
                      defaultValue={
                          formSubject && fields?.subjectOptions.map(({ value }) => value).indexOf(formSubject.value) + 1
                      }
                      name="subjectLabel"
                      inputRef={register({
                        required: false,
                        validate: {
                          selectOne: value =>
                              value !== '0' ||
                              translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty'),
                        },
                      })}
                      onChange={event => {
                        const subject = Array.isArray(fields?.subjectOptions) && fields?.subjectOptions[event.target.value - 1];
                        setFormSubject(subject);
                      }}
                  />
                </div>
                <div className="about-section">
                  {fields?.contactInfoSectionTitle && <Text tag="h2" field={fields?.contactInfoSectionTitle} />}
                  <div className="field-row">
                    <div>
                      <Input
                          type="text"
                          required
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNameLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNamePlaceholder')}
                          name="firstName"
                          error={errors?.firstName?.message}
                          inputRef={register({
                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.FirstNameValidation'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                              );
                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.FirstNameValidation');
                            },
                          })}
                      />
                    </div>
                    <div>
                      <Input
                          type="text"
                          required
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNameLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNamePlaceholder')}
                          name="lastName"
                          error={errors?.lastName?.message}
                          inputRef={register({
                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LastNameValidation'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                              );
                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LastNameValidation');
                            },
                          })}
                      />
                    </div>
                  </div>
                  <div className="field-row">
                    <div>
                      <Input
                          type="text"
                          required
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.EmailAddressLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.EmailAddressPlaceholder')}
                          name="email"
                          error={errors?.email?.message}
                          inputRef={register({
                            required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation'),
                            validate: value => {
                              const validation = Joi.string().email({ tlds: { allow: false } });
                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation');
                            },
                          })}
                      />
                    </div>
                    <div>
                      <Input
                          type="text"
                          required={formSubject?.value === 'Product Feedback'}
                          name="telephone"
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TelephoneLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TelephonePlaceholder')}
                          error={errors?.telephone?.message}
                          inputRef={register({
                            required:
                                formSubject?.value === 'Product Feedback' &&
                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.TelephoneValidation'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  /^\W?\d*?\W*?(?<area>\d{3})\W*?(?<group1>\d{3})\W*?(?<group2>\d{4})\W*?$/
                              );
                              if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                return true;
                              }
                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.TelephoneValidation');
                            },
                          })}
                      />
                    </div>
                  </div>
                  <div className="field-row">
                    <div>
                      <Input
                          type="text"
                          required={formSubject?.value === 'Product Feedback'}
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddressLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddressPlaceholder')}
                          name="mailingAddress"
                          error={errors?.mailingAddress?.message}
                          inputRef={register({
                            required:
                                formSubject?.value === 'Product Feedback' &&
                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  /^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-,#]+[\s]*[a-zA-Z0-9.\-,#]+[a-zA-Z0-9\s.\-,#]*$/
                              );
                              if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                return true;
                              }
                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation');
                            },
                          })}
                      />
                    </div>
                    <div>
                      <Input
                          type="text"
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddress2Label')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddress2Placeholder')}
                          name="mailingAddressTwo"
                          error={errors?.mailingAddressTwo?.message}
                          inputRef={register({
                            required: false,
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  /^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-,#]+[\s]*[a-zA-Z0-9.\-,#]+[a-zA-Z0-9\s.\-,#]*$/
                              );
                              if (value.length === 0) {
                                return true;
                              }
                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation');
                            },
                          })}
                      />
                    </div>
                  </div>
                  <div className="field-row">
                    <div>
                      <Input
                          type="text"
                          required={formSubject?.value === 'Product Feedback'}
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CityLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CityPlaceholder')}
                          name="city"
                          error={errors?.city?.message}
                          inputRef={register({
                            required:
                                formSubject?.value === 'Product Feedback' &&
                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.CityValidation'),
                            validate: value => {
                              const validation = Joi.string().pattern(
                                  /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                              );
                              if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                return true;
                              }
                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.CityValidation');
                            },
                          })}
                      />
                    </div>
                    <div>
                      <Select
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.StateProvinceLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.StateProvincePlaceholder')}
                          options={[
                            {value: 'Alabama', label: 'Alabama'},
                            {value: 'Alaska', label: 'Alaska'},
                            {value: 'Arizona', label: 'Arizona'},
                            {value: 'Arkansas', label: 'Arkansas'},
                            {value: 'California', label: 'California'},
                            {value: 'Colorado', label: 'Colorado'},
                            {value: 'Connecticut', label: 'Connecticut'},
                            {value: 'Delaware', label: 'Delaware'},
                            {value: 'Florida', label: 'Florida'},
                            {value: 'Georgia', label: 'Georgia'},
                            {value: 'Hawaii', label: 'Hawaii'},
                            {value: 'Idaho', label: 'Idaho'},
                            {value: 'Illinois', label: 'Illinois'},
                            {value: 'Indiana', label: 'Indiana'},
                            {value: 'Iowa', label: 'Iowa'},
                            {value: 'Kansas', label: 'Kansas'},
                            {value: 'Kentucky', label: 'Kentucky'},
                            {value: 'Louisiana', label: 'Louisiana'},
                            {value: 'Maine', label: 'Maine'},
                            {value: 'Maryland', label: 'Maryland'},
                            {value: 'Massachusetts', label: 'Massachusetts'},
                            {value: 'Michigan', label: 'Michigan'},
                            {value: 'Minnesota', label: 'Minnesota' },
                            {value: 'Mississippi', label: 'Mississippi' },
                            {value: 'Missouri', label: 'Missouri' },
                            {value: 'Montana', label: 'Montana' },
                            {value: 'Nebraska', label: 'Nebraska' },
                            {value: 'Nevada', label: 'Nevada' },
                            {value: 'New Hampshire', label: 'New Hampshire' },
                            {value: 'New Jersey', label: 'New Jersey' },
                            {value: 'New Mexico', label: 'New Mexico' },
                            {value: 'New York', label: 'New York' },
                            {value: 'North Carolina', label: 'North Carolina' },
                            {value: 'North Dakota', label: 'North Dakota' },
                            {value: 'Ohio', label: 'Ohio' },
                            {value: 'Oklahoma', label: 'Oklahoma' },
                            {value: 'Oregon', label: 'Oregon' },
                            {value: 'Pennsylvania', label: 'Pennsylvania' },
                            {value: 'Rhode Island', label: 'Rhode Island' },
                            {value: 'South Carolina', label: 'South Carolina' },
                            {value: 'South Dakota', label: 'South Dakota' },
                            {value: 'Tennessee', label: 'Tennessee' },
                            {value: 'Texas', label: 'Texas' },
                            {value: 'Utah', label: 'Utah' },
                            {value: 'Vermont', label: 'Vermont' },
                            {value: 'Virginia', label: 'Virginia' },
                            {value: 'Washington', label: 'Washington' },
                            {value: 'Washington, D.C.', label: 'Washington, D.C.' },
                            {value: 'West Virginia', label: 'West Virginia' },
                            {value: 'Wisconsin', label: 'Wisconsin' },
                            {value: 'Wyoming', label: 'Wyoming' },
                            {value: 'Alberta', label: 'Alberta' },
                            {value: 'British Columbia', label: 'British Columbia' },
                            {value: 'Manitoba', label: 'Manitoba' },
                            {value: 'New Brunswick', label: 'New Brunswick' },
                            {value: 'Newfoundland & Labrador', label: 'Newfoundland & Labrador' },
                            {value: 'Nova Scotia', label: 'Nova Scotia' },
                            {value: 'Northwest Territories', label: 'Northwest Territories' },
                            {value: 'Nunavut', label: 'Nunavut' },
                            {value: 'Ontario', label: 'Ontario' },
                            {value: 'Prince Edward Island', label: 'Prince Edward Island' },
                            {value: 'Quebec', label: 'Quebec' },
                            {value: 'Saskatchewan', label: 'Saskatchewan' },
                            {value: 'Yukon', label: 'Yukon' },
                          ]}
                          required={formSubject?.value === 'Product Feedback'}
                          error={errors?.stateOrProvinceOptions?.message}
                          name="stateOrProvinceOptions"
                          inputRef={register({
                            required:
                                formSubject?.value === 'Product Feedback' &&
                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty'),
                            validate: {
                              selectOne: value =>
                                  formSubject?.value === 'Product Feedback' && value === '0'
                                      ? translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty')
                                      : true,
                            },
                          })}
                      />

                    </div>
                  </div>
                  <div className="field-row">
                    <div>
                      <Select
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CountryLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CountryPlaceholder')}
                          options={[
                            { value: 'United States of America', label: 'United States of America' },
                            { value: 'Canada', label: 'Canada' }
                          ]}
                          name="countryOptions"
                          inputRef={register({
                            required: false,
                          })}
                      />
                    </div>
                    <div id="usps-note">
                      <Input
                          type="text"
                          required={formSubject?.value !== 'Privacy Request'}
                          label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ZipCodeLabel')}
                          placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ZipCodePlaceholder')}
                          name="zip"
                          error={errors?.zip?.message}
                          inputRef={register({
                            required:
                                formSubject?.value !== 'Privacy Request' &&
                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ZipCodeValidation'),
                            validate: value => {
                              const validation = Joi.string().pattern(zipCodeRegex);

                              if (formSubject?.value === 'Privacy Request') {
                                return true;
                              }

                              return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ZipCodeValidation');
                            },
                          })}
                      />
                    </div>
                  </div>
                  <div className="contact-form-divider" />
                  {formSubject?.value !== 'Privacy Request' && (
                      <div className="field-row-textarea">
                        <div>
                          <Textarea
                              rows="10"
                              className="textarea"
                              label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.YourMessageLabel')}
                              maxLength={1500}
                              required
                              name="yourMessage"
                              helpText="(be as descriptive as possible in your request)"
                              error={
                                errors?.yourMessage?.type === 'maxLength'
                                    ? translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.YourMessageCharacterLimitValidation')
                                    : errors?.yourMessage?.type === 'minLength'
                                        ? 'Message is too short'
                                        : errors?.yourMessage?.message
                              }
                              inputRef={register({
                                minLength: 1,
                                maxLength: 1500,
                                required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.YourMessageEmptyValidation'),
                              })}
                          />
                        </div>
                      </div>
                  )}
                </div>
                <div className="form-footer">
                  <div className="submit-button-container">
                    <Button
                        text={translate(viewBag, 'BushsBeans.Forms.ContactUs.Buttons.ClearAll', 'Clear All')}
                        className="clear-button blue-text"
                        noIcon
                        onClick={handleReset}
                    />
                    <Button
                        text={translate(viewBag, 'BushsBeans.Forms.ContactUs.Buttons.Submit', 'Submit')}
                        type="submit"
                        variant="blue"
                        noIcon
                    />
                  </div>
                  <RichText tag="div" field={fields?.privacyPolicyText} />
                </div>
              </FormContent>
            </form>
        )}
    </ContactRepFormComponent>
  );
};

ContactRepForm.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
  rendering: PropTypes.object,
};

export default withSitecoreContext()(ContactRepForm);