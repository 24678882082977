import React from 'react';
import PropTypes from 'prop-types';
import {withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {
    ScrollingVanityTextWithVideoComponent, ScrollingVanityTextWithVideoFS,
    VideoWrapper,
} from './ScrollingVanityTextWithVideo.styles.js';
import {isServer} from '@sitecore-jss/sitecore-jss';

import Video from 'components/shared/Video';
import ScrollingText from 'components/shared/ScrollingText';


const ScrollingVanityTextWithVideo = ({fields = {}, params = {}, sitecoreContext}) => {
    const {videoId = {}, scrollingVanityTextElements = []} = fields;
    const {backgroundColor = 'tan', id = ''} = params;
    const {site} = sitecoreContext;

    if (site.name === 'bushs-beans-foodservices') {
        return (
            <ScrollingVanityTextWithVideoFS
                id={id}
                backgroundColor={backgroundColor}
                hasVideo={!!videoId?.value}
                className={!videoId?.value ? 'no-video' : ''}
            >
                <div className="inner-wrapper">
                    {videoId?.value &&
                        <VideoWrapper>{!isServer() && <Video youtubeId={videoId?.value}/>}</VideoWrapper>}
                    {scrollingVanityTextElements && (
                        <ScrollingText scrollingVanityTextElements={scrollingVanityTextElements}/>
                    )}
                </div>
            </ScrollingVanityTextWithVideoFS>
        )
    } else {
        return (
            <ScrollingVanityTextWithVideoComponent
                id={id}
            backgroundColor={backgroundColor}
            hasVideo={!!videoId?.value}
            className={!videoId?.value ? 'no-video' : ''}
        >
          {videoId?.value && <VideoWrapper>{!isServer() && <Video youtubeId={videoId?.value} />}</VideoWrapper>}
          {scrollingVanityTextElements && (
              <ScrollingText scrollingVanityTextElements={scrollingVanityTextElements} />
          )}
        </ScrollingVanityTextWithVideoComponent>
    );
  }
};

ScrollingVanityTextWithVideo.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object.isRequired,
};

export default withSitecoreContext()(ScrollingVanityTextWithVideo);