import styled from "styled-components";

export const ProductDescriptionWrapper = styled.section`
    position: relative;
    background-color: ${({ theme }) => theme.colours.tan};
	padding: ${({ theme }) => theme.spacing({ top: 120, bottom: 100, left: 35, right: 35 })};
`;

export const ProductDescriptionInner = styled.div`
    position: relative;
    .container{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 66px;
        width: 1300px;
        margin: 0 auto;
        max-width: 100%;
		@media (max-width: 1000px) {
			grid-template-columns: 1fr;
		}
        .product-description{
            h2{
				font-family: 'apercu condensed pro', apercu condensed pro, 'apercu pro', impact;
				font-size: 56px;
				font-style: normal;
				font-weight: 700;
				line-height: 60px;
				text-transform: uppercase;
                text-align: left;
            }
            p{
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 26px;
            }
        }
    }
    .download-link{
        color: ${({ theme }) => theme.colours.brown};
        display: flex;
        font-size: 20px;
		line-height: 25px;
		margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        .link-text{
			font-family: 'apercu condensed pro', apercu condensed pro, 'apercu pro', impact;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: 32px;
			text-decoration-line: underline;
			text-transform: uppercase;
        }
        .circle{
			display: flex;
            justify-content: center;
			border-radius: 50%;
			height: 25px;
			width: 25px;
			position: relative;
			top: 2px;
			margin: 0 0 0 10px;
			text-align: center;
			background-color: ${({ theme }) => theme.colours.brown};
            svg{
                fill: ${({ theme }) => theme.colours.tan};
				width: 15px;
				align-self: center;
				display: flex;
				justify-self: center;
				position: relative;
				top: -2px;
				transition: all 0.2s ease-in-out;
            }
        }
        &:hover{
            .circle{
                svg{
                    top: 0px;
                }
            }
        }
    }
`;

export const ProductDetailBox = styled.div`
    position: relative;
    border: 2px solid ${({ theme }) => theme.colours.blue};
    background-color: #fff;
    h3{
        display: block;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: 34px; /* 106.25% */
		text-transform: uppercase;
        padding: 10px 40px;
        color: ${({ theme }) => theme.colours.yellow};
        background-color: ${({ theme }) => theme.colours.blue};
        text-align: left;
        margin: 0;
    }
    
`;


export const DetailBoxList = styled.div`
    position: relative;
    padding: 40px;
    display: grid;
    .info-cols{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 40px;
        margin-bottom: 20px;
		.product-details-item{
			display: flex;
			justify-content: left;
			margin-bottom: 0;
			.label{
                font-weight: 900;
				margin-right: 15px;
			}
		}
    }
	.product-details-item-full-width{
		.label{
			font-weight: 900;
			margin-right: 10px;
		}
	}
    @media (max-width: 700px) {
        .info-cols{
            grid-template-columns: 1fr;
        }
    }
`;