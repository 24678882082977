import React from 'react';
import PropTypes from 'prop-types';
import {
  PromoBoxComponent,
  RecipeGrid,
  PromoBoxInner,
  RecipeImageWrapper,
  LogoWrapper,
  LogoAndLongNameWrapper,
  PromoBoxOuter,
} from './RecipePromo.styles.js';
import { Text } from 'components/shared/JssOverrides';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import Button from 'components/shared/Button';
import { useDeviceResize } from 'hooks';
import translate from 'helpers/translate';

const RecipePromoBox = ({ longName, copy, recipeHighlights, viewBag, itemId, sitecoreContext = {} }) => {
  const device = useDeviceResize();
  const prepTimeText = translate(viewBag, 'BushsBeans.Recipes.RecipeHighlights.PrepTime', 'Prep Time');
  const cookTimeText = translate(viewBag, 'BushsBeans.Recipes.RecipeHighlights.CookTime', 'Cook Time');
  const servesText = translate(viewBag, 'BushsBeans.Recipes.RecipeHighlights.Serves', 'Serves');
  const hourText = translate(viewBag, 'BushsBeans.Recipes.Time.Hour', 'Hour');
  const hoursText = translate(viewBag, 'BushsBeans.Recipes.Time.Hours', 'Hours');
  const minText = translate(viewBag, 'BushsBeans.Recipes.Time.Minute', 'Minute');
  const minsText = translate(viewBag, 'BushsBeans.Recipes.Time.Minutes', 'Minutes');
  const { site } = sitecoreContext;
  const isFoodserviceSite = site.name === 'bushs-beans-foodservices';
  const recipeItems = recipeHighlights?.length && Object.assign(...recipeHighlights, {});

  const checkRecipeGridItemHasValue = (recipeValue) => {
    if (typeof recipeValue !== undefined && recipeValue?.value && recipeValue?.value !== '0') {
      return recipeValue.value;
    }

    return;
  };

  //Object destructuring to pull through recipe image
  const { globalContent = {} } = sitecoreContext;
  const { metadata = {}, logo = {} } = globalContent;
  const { metadataImage = '' } = metadata;
  const { src = '' } = logo;

  const prepTimeHours = checkRecipeGridItemHasValue(recipeItems?.prepTimeHours);
  const prepTimeMinutes = checkRecipeGridItemHasValue(recipeItems?.prepTimeMinutes);
  const cookTimeHours = checkRecipeGridItemHasValue(recipeItems?.cookTimeHours);
  const cookTimeMinutes = checkRecipeGridItemHasValue(recipeItems?.cookTimeMinutes);
  const servings = checkRecipeGridItemHasValue(recipeItems?.servings);
  const healthAttributeOneText = checkRecipeGridItemHasValue(recipeItems?.healthAttribute1?.fields?.text);
  const healthAttributeOneIcon = checkRecipeGridItemHasValue(recipeItems?.healthAttribute1?.fields?.icon);
  const healthAttributeTwoText = checkRecipeGridItemHasValue(recipeItems?.healthAttribute2?.fields?.text);
  const healthAttributeTwoIcon = checkRecipeGridItemHasValue(recipeItems?.healthAttribute1?.fields?.icon);
  const healthAttributeThreeText = checkRecipeGridItemHasValue(recipeItems?.healthAttribute3?.fields?.text);
  const healthAttributeThreeIcon = checkRecipeGridItemHasValue(recipeItems?.healthAttribute1?.fields?.icon);

  const gridItems = [
    {
      label: prepTimeText,
      hours: prepTimeHours,
      minutes: prepTimeMinutes,
      hoursText: prepTimeHours === '1' ? hourText : hoursText,
      minsText: prepTimeMinutes === '1' ? minText : minsText,
    },
    {
      label: cookTimeText,
      hours: cookTimeHours,
      minutes: cookTimeMinutes,
      hoursText: cookTimeHours === '1' ? hourText : hoursText,
      minsText: cookTimeMinutes === '1' ? minText : minsText,
    },
    {
      label: servesText,
      servings,
    },
    {
      label: healthAttributeOneText,
      icon: healthAttributeOneIcon,
    },
    {
      label: healthAttributeTwoText,
      icon: healthAttributeTwoIcon,
    },
    {
      label: healthAttributeThreeText,
      icon: healthAttributeThreeIcon,
    },
  ];

  // check label exists
  const gridItemsFilter = gridItems.filter((item) => item?.label);

  return (
    <PromoBoxComponent className={`format-promo-box-on-print ${isFoodserviceSite ? 'fs-recipe' : ''}`}>
      <PromoBoxInner>
        <LogoAndLongNameWrapper>
          <LogoWrapper>{logo?.src && <img src={src} alt="logo" />}</LogoWrapper>
          {longName && <Text tag="h1" field={longName} className="recipe-title-on-print" />}
        </LogoAndLongNameWrapper>
        {copy && <Text tag="p" field={copy} className="recipe-description-on-print shrink-on-print" />}
      </PromoBoxInner>
      <PromoBoxOuter>
        {recipeItems && (
          <RecipeGrid results={gridItemsFilter?.length} className="recipe-grid-on-print top-c-time">
            {gridItemsFilter?.map(({ label, hours, minutes, hoursText, minsText, servings, icon }, index) => (
              <li key={`Recipe-Promo-${index}`} className="recipe-grid-items-on-print">
                <span className="flex-on-print uniform-text-on-print colon-on-print"> {label}</span>
                <p className="flex-on-print uniform-text-on-print">
                  {hours?.length && `${hours} ${hoursText}`} {minutes?.length && `${minutes} ${minsText}`}
                  {servings?.length && servings}
                  {icon?.src?.length && <img src={icon.src} alt={label} />}
                </p>
              </li>
            ))}
          </RecipeGrid>
        )}
        {metadataImage && (
          <RecipeImageWrapper>
            <img src={metadataImage} alt="recipe" />
          </RecipeImageWrapper>
        )}
      </PromoBoxOuter>
      {!isServer() && (
        <Button
          type="love"
          size={device === 'mobile' ? 'small' : 'large'}
          itemId={itemId}
          class="hide-on-print"
          aria-label="Favorite Recipe"
        />
      )}
    </PromoBoxComponent>
  );
};

RecipePromoBox.propTypes = {
  copy: PropTypes.object,
  longName: PropTypes.object,
  variant: PropTypes.oneOf(['red', 'yellow', 'blue', 'transparent']),
};

export default withSitecoreContext()(RecipePromoBox);