import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    yellow: {
      background: theme.colours.yellow,
    },
    tan: {
      background: theme.colours.tan,
    },
    blue: {
      background: theme.colours.blue,
    },
    brown: {
      background: theme.colours.brown,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

export const ResultsComponent = styled.div`
  padding: ${({ theme }) => theme.spacing(60)} 0;
  background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ variant: backgroundColor, theme }).background};

  .loading-spinner {
    height: 400px;
  }

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => theme.spacing(80)} 0;
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    padding: ${({ theme }) => theme.spacing(100)} 0;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(80)};

  button {
    margin: 0 auto;
  }

  .loading-spinner {
    height: 400px;
  }
`;